<div class="full-width">
  <div class="row page-title">
    <div class="col-md-6 col-sm-6 col-xs-12">
      <h3>الوزارات</h3>
    </div>

    <div class="col-md-6 col-sm-6 col-xs-12">
      <nav aria-label="breadcrumb" class="titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
          <li class="breadcrumb-item" style="padding-left: 0px">
            <a routerLink="partener/ministry" style="padding-right: 10px">الوزارات</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>
<div class="container">
  <div class="row" style="margin-top: 30px; margin-bottom: 30px">
    <div class="col-md-3 col-sm-4 col-xs-12" *ngFor="let item of ministries_list">

      <!-- if item contain link -->
      <a *ngIf="item.link !== ''" [href]="item.link">
        <img class="partner-img" [src]="item.image" />
      </a>

      <!-- if item link empty -->
      <a *ngIf="item.link == ''">
        <img class="partner-img" [src]="item.image" />
      </a>
    </div>
  </div>
</div>
