import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-management-heads',
  templateUrl: './home-management-heads.component.html',
  styleUrls: ['./home-management-heads.component.scss']
})
export class HomeManagementHeadsComponent implements OnInit {
  @Input() managers_list;

  constructor() { }

  ngOnInit(): void {

  }

}
