import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AuthenticationService } from "../../services/authentication.service";
import { PasswordValidators } from "../../functions/confirm-password";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent implements OnInit {

  isVerify: boolean = false;

  email: string = '';

  registrationForm: FormGroup = new FormGroup(
    {
      full_name: new FormControl(null, [Validators.required]),
      mobile: new FormControl(null, [
        Validators.required
      ]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
      confirm_password: new FormControl(null, [Validators.required]),
    },
    PasswordValidators.mustMatch("password", "confirm_password")
  );

  verifyForm: FormGroup = new FormGroup(
    {
      email: new FormControl(null, [Validators.required]),
      code: new FormControl(null, [Validators.required]),
    }
  )

  constructor(
    private authenticationService: AuthenticationService,
    private toaster: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void { }

  submitForm() {
    this.authenticationService
      .registerForm(this.registrationForm.value)
      .subscribe(
        (res: any) => {
          // this.router.navigateByUrl("/home");
          this.isVerify = true;

          if(res.status.code == 403){
            console.log(res.status);

            let validation_error = res.status.validation_errors;
            console.log(res.status.validation_errors);
            validation_error.forEach(element => {
              if (element.field == 'full_name') {
                this.toaster.error(element.message);
              } else if (element.field == 'mobile') {
                this.toaster.error(element.message);
              } else if (element.field == 'email') {
                this.toaster.error(element.message);
              } else if (element.field == 'password') {
                this.toaster.error(element.message);
              } else if (element.field == 'confirm_password') {
                this.toaster.error(element.message);
              }

        })

          }else if (res.status.code == 200){
            this.toaster.success('تم بنجاح');
          }
        },
        (err) => {
          // this.toaster.error(err.error.status.validation_errors[0].message);
          console.log("the error ::::::::::",err)
          if(err.status == 403){
            console.log(err.error.status.validation_errors);

            let validation_error = err.error.status.validation_errors;
            console.log(err.error.status.validation_errors);
            validation_error.forEach(element => {
              if (element.field == 'full_name') {
                this.toaster.error(element.message);
              } else if (element.field == 'mobile') {
                this.toaster.error(element.message);
              } else if (element.field == 'email') {
                this.toaster.error(element.message);
              } else if (element.field == 'password') {
                this.toaster.error(element.message);
              } else if (element.field == 'confirm_password') {
                this.toaster.error(element.message);
              }

        })

          }
        }
      );
  }

  verify() {
    let body = {
      email: this.verifyForm.value.email,
      confirm_verification_code: this.verifyForm.value.code,
      email_verification_code: this.verifyForm.value.code,
    };

    this.authenticationService.sendVerificationCode(body).subscribe((res: any) => {
       if (res.status.code == 200){
        this.toaster.success('تم بنجاح');
      }
      this.router.navigateByUrl('/login');
    }, (err) => {
      this.toaster.error(err.error.status.validation_errors[0].message);
    })
  }
}
