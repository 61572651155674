<div class="row">

    <div class="col-md-6 col-sm-6 col-xs-12" style="margin-bottom:30px">
        <a routerLink="/learn-more">
            <div  class="card">
                <div class="row">
                    <div class="col-md-5 col-sm-5 col-xs-12">
                        <img class="card-img-top" src="assets/images/images.jpeg">
                    </div>
                    <div class="col-md-7 col-sm-7 col-xs-12">
                        <h3 class="card-title"> ثقف نفسك </h3>
                       
                        <p  class="">
                            تقع الكلى في الجانب العلوي والخلفي من البطن وعلى جانبي العمود الفقري، والكلى محمية من التلف عن طريق الأضلاع السفلى، وتعد الكلية من بين الأعضاء الأكثر حيوية في الجسم البشري ووجود خلل وظيفي بها يؤدي إلى الإصابة بأمراض خطيرة أو حتى الموت.
                            تنقسم أمراض الكلى إلى أمراض طبية وأمراض جراحية.
                        </p>
        
                        <button class="red-btn" > إقرأ المزيد</button>
                    </div>
        
                </div>
            </div>
        </a>
    </div>

    <div class="col-md-6 col-sm-6 col-xs-12" style="margin-bottom:30px">
        <a routerLink="/patient-rights">
            <div  class="card">
                <div class="row">
                    <div class="col-md-5 col-sm-5 col-xs-12">
                        <img class="card-img-top" src="assets/images/home_adagency_about_bg1.png">
                    </div>
                    <div class="col-md-7 col-sm-7 col-xs-12">
                        <h3 class="card-title">  حقوق مرض الفشل العضوي النهائي </h3>
                       
                        <p  class="">
                            حظى القطاع الصحي بالمملكة باهتمام خاص من
                            حكومة خادم الحرمين الشريفين - حفظه الله -
                            حيث باتت الخدمات الصحية المتطورة المقدمة
                            للمرضى بشكل عام و مرضى الفشل الكلوي على
                            الأخص تنافس مثيلاتها في دول العالم
                        </p>
        
                        <button class="red-btn" style="margin-top: 55px;"> إقرأ المزيد</button>
                    </div>
        
                </div>
            </div>
        </a>
    </div>


</div>
