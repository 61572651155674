import { AppInfoService } from './../../../shared/shared_services/app-info.service';
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AuthenticationService } from "../../services/authentication.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Location } from "@angular/common";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [Validators.required]),
  });

  constructor(
    private authenticationService: AuthenticationService,
    private toaster: ToastrService,
    private router: Router,
    private _location: Location,
    private appInfoService: AppInfoService,
  ) {
  }

  ngOnInit(): void {

  }


  submitForm() {
    this.authenticationService.loginForm(this.loginForm.value).subscribe(
      (res: any) => {

        console.log("error response :::::::::",res)

        const user = res.content;
        localStorage.setItem("APP_ID", user.application_id);
        localStorage.setItem("email", user.email);
        localStorage.setItem("username", user.username);
        localStorage.setItem("login_token", user.api_token);
        localStorage.setItem("mobile", user.mobile);
        localStorage.setItem("User_ID", user.id);

        this.appInfoService.currentUser.next(user.username);
        this.appInfoService.currentToken.next(user.api_token);
        this.appInfoService.isNotify.next(true)
        // localStorage.setItem('login_assoc', user.association.name);
        // localStorage.setItem('association_id', user.association.id);
        this.router.navigateByUrl("/home").then(() => window.location.reload());
        this._location.back();

      },
      (err) => {
        this.toaster.error(err.error.status.error_details);
        console.log("error :::::::::",err.error.status.error_details)
      }
    );
  }
}
