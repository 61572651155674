import { Component, OnInit } from '@angular/core';
import {CommonGeneralService} from '../services/common-general.service'

@Component({
  selector: 'app-patient-rights',
  templateUrl: './patient-rights.component.html',
  styleUrls: ['./patient-rights.component.scss']
})
export class PatientRightsComponent implements OnInit {

  right_list;
  info;
  constructor(private service: CommonGeneralService) { }

  ngOnInit(): void {

    this.service.getinfoRightslearnlist().subscribe((res:any)=>{
      this.info = res.content[0]
      console.log(" Patient Rights res :::::::::::", this.info)

    })

    this.service.getPatientRightslist().subscribe((res:any)=>{
      this.right_list = res.content ;
      console.log(" Patient Rights list res :::::::::::", this.right_list)
    })
  }

}
