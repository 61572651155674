import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';
import { HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubscribeService {

  header: HttpHeaders = new HttpHeaders();

  constructor(
    private baseHttpService: BaseHttpService
  ) { }

  PostEmail(body) {
    const headerNotification = this.header.set('access-token', localStorage.getItem('login_token'));
    return this.baseHttpService.post('/api/mail_list/store', { ...body, application_id: 188, module_id: 2922 });

  }


}
