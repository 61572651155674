import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { EmbedVideoService } from 'ngx-embed-video';
import { DomSanitizer } from '@angular/platform-browser';
import { HomeService } from '../../../general-pages/pages/home/home.service';


@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  iframe_html: any;
  youtubeUrl = "https://www.youtube.com/watch?v=iHhcHTlGtRs";
  youtubeId = 'iHhcHTlGtRs';


  video_array: any[] = [];
  slides: any[] = [];
  test_link;


  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    
    navigation: true,
    pagination: this.pagination,
    // grabCursor: true,     
    
    effect: 'slide',
    speed:1500,
  };
  

  constructor(private homeService: HomeService,
    private embedService: EmbedVideoService,
    public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.homeService.getHomeSlider().subscribe((res: any) => {
      this.slides = res.content;
      this.test_link = this.slides[0].link;
      console.log('test link =>' , this.slides[0].link);
      this.config.loop = true;
      this.config.autoplay = true;
      console.log("Slider Data:-",this.slides)
          
      
    });
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if(videoLink.indexOf('youtube') > -1 ) {
      videoId = videoLink.split('watch?v=')[1];
      return `https://www.youtube.com/embed/${videoId}`
    } 
    else if(videoLink.indexOf('vimeo.com') > -1 ) {
      videoId = videoLink.split('vimeo.com')[1];
      return `https://player.vimeo.com/video${videoId}`
    }
    else {
      return videoLink;

    }

  }

}
