import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { CommonGeneralService } from "../services/common-general.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  @ViewChild("mapContainer", { static: false }) gmap: ElementRef;

  submitted = false;

  guestValue = false;

  // map
  selectedTab = 1;

  title = "angular-gmap";

  map: google.maps.Map;

  lat = 40.73061;

  lng = -73.935242;

  coordinates = new google.maps.LatLng(0, 0);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 8,
  };

  marker: any;

  contactData: any = {};

  contactForm: FormGroup;

  complaintsForm: FormGroup;

  formdata = new FormData();

  messageTitle;

  message_id;

  file_url: any;

  uploaded_file = false;

  fileName: any;

  constructor(
    public fb: FormBuilder,
    private commonPagesService: CommonGeneralService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.restForm();

    // get contact us data from api
    this.commonPagesService.getContactUsData().subscribe((res: any) => {
      this.contactData = res.content[0];
      const locationStr: string = this.contactData.location;
      const params = locationStr
        .split("https://maps.google.com/maps?ll=")[1]
        .split("&");
      const latlng: string[] = params[0].split(",");
      const zoom = params[1].split("=")[1];
      const newCoordinates = new google.maps.LatLng(
        parseFloat(latlng[0]),
        parseFloat(latlng[1])
      );
      this.mapOptions.center = newCoordinates;
      this.mapOptions.zoom = parseFloat(zoom);
    });
    this.commonPagesService.getContactMessageTitle().subscribe((res: any) => {
      this.messageTitle = res.content;
    });
  }

  ngAfterViewInit() {
    this.mapInitializer();
  }

  // google map initializer
  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.marker = new google.maps.Marker({
      position: this.mapOptions.center,
      map: this.map,
    });
    this.marker.setMap(this.map);
  }

  // rest form
  restForm() {
    this.contactForm = this.fb.group({
      name: ["", [Validators.required]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$"
          ),
        ],
      ],
      message: ["", [Validators.required]],
    });

    this.complaintsForm = this.fb.group({
      name: ["", [Validators.required]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$"
          ),
        ],
      ],
      mobile: [
        "",
        [Validators.required, Validators.pattern("^((009665|9665|\\+9665)|05|5)(5|0|3|6|4|9|1|8|7)?[0-9]{7}$")],
      ],
      title: [""],
      message: ["", [Validators.required]],
      document: ["", [Validators.required]],
      answer: [""],
      address: [""],
    });
  }

  readImage(event) {
    const files = event.target.files[0];
    this.fileName = event.target.files[0].name;

    const reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }

  handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.file_url = "data:application/;base64," + btoa(binaryString);
    this.uploaded_file = true;
  }

  // submit forms
  submit(formValue) {
    this.submitted = true;
    const contactUsForm = {
      application_id: 188,
      lang_id: 2,
      name: this.contactForm.value.name,
      email: this.contactForm.value.email,
      mobile: this.contactForm.value.mobile,
      message: this.contactForm.value.message,
      message_id: this.message_id,
      document: this.file_url,
    };
    if (this.selectedTab === 1) {
      if (this.contactForm.valid) {
        this.commonPagesService
          .submitContactUsForm(contactUsForm)
          .subscribe((res: any) => {
            console.log("Contact Us Form Response :", res);

            if (res.status.code === 200) {
              this.toastr.success('تم بنجاح');
              this.restForm();
            } else if (res.status.code === 401) {
              this.toastr.error(res.status.error_details);
            } else if (res.status.code === 403) {
              const validation_error = res.status.validation_errors;
              validation_error.forEach((element) => {
                if (element.field === "name") {
                  this.toastr.error(element.message);
                } else if (element.field === "email") {
                  this.toastr.error(element.message);
                } else if (element.field === "mobile") {
                  this.toastr.error(element.message);
                } else if (element.field === "message") {
                  this.toastr.error(element.message);
                } else if (element.field === "message_id") {
                  this.toastr.error(element.message);
                } else if (element.field === "document") {
                  this.toastr.error(element.message);
                }
              });
            }
          });
      } else {
        this.toastr.warning("الرجاء التحقق من البيانات المدخلة");
      }
    } else {
      const complaintForm = {
        application_id: 188,
        lang_id: 2,
        name: this.complaintsForm.value.name,
        email: this.complaintsForm.value.email,
        mobile: this.complaintsForm.value.mobile,
        message: this.complaintsForm.value.message,
        message_id: this.message_id,
        document: this.file_url,
        address: this.complaintsForm.value.address,
        module_id: 2889,
      };

      if (this.complaintsForm.valid) {
        this.commonPagesService
          .submitComplaintForm(complaintForm)
          .subscribe((res: any) => {
            // if success
            if (res.status.code === 200) {
              this.toastr.success('تم بنجاح');
              this.restForm();

              // else if error
            } else if (res.status.code === 401) {
              this.toastr.error(res.status.error_details);
            } else if (res.status.code === 403) {
              const validation_error = res.status.validation_errors;

              validation_error.forEach((element) => {
                if (element.field === "name") {
                  this.toastr.error(element.message);
                } else if (element.field === "email") {
                  this.toastr.error(element.message);
                } else if (element.field === "mobile") {
                  this.toastr.error(element.message);
                } else if (element.field === "message") {
                  this.toastr.error(element.message);
                } else if (element.field === "message_id") {
                  this.toastr.error(element.message);
                } else if (element.field === "document") {
                  this.toastr.error(element.message);
                }
              });
            }
          });
      } else {
        this.toastr.warning("الرجاء التحقق من البيانات المدخلة");
      }
    }
  }

  tab(e) {
    this.selectedTab = e;
  }
}
