<div class="full-width">
  <div class="row page-title">
    <div class="col-md-6 col-sm-6 col-xs-12">
      <h3>الخطة الاستراتيجية</h3>
    </div>

    <div class="col-md-6 col-sm-6 col-xs-12">
      <nav aria-label="breadcrumb" class="titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
          <li class="breadcrumb-item" style="padding-left: 0px">
            <a routerLink="about/stratigic" style="padding-right: 10px">
              الخطة الاستراتيجية</a
            >
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<div class="container" *ngIf="stratigic">
  <div class="row">
    <!-- make for loop to view strategic data -->
    <div
      *ngFor="let item of stratigic"
      class="col-md-6 col-sm-6 col-xs-12 pt-3"
      style="margin-bottom: 20px"
    >
      <div class="card">
        <div class="beside">
          <img class="stratigic-icon" src="{{ item.image }}" alt="card-icon" />
          <h3 style="margin-top: 15px" class="text-dark">{{ item.title }}</h3>
        </div>
        <div>
          <p *ngFor="let line of item?.content?.split('.')">
            {{ line | textFormatter }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row padd" style="background: #fbfbfb">
  <div class="col-md-12 col-sm-12 col-xs-12" style="margin-bottom: 30px">
    <div class="beside" *ngIf="stratigic">
      <img
        class="stratigic-icon"
        src="assets/images/Group 2039.svg"
        alt="card-icon"
      />
      <h3 style="margin-top: 15px" class="text-dark">قيم الجمعية</h3>
    </div>
  </div>
  <div class="col-md-2 col-sm-4 col-xs-3 small-width">
    <div class="circle">
      <h2>1</h2>
      <h6>الإبداع</h6>
    </div>
  </div>
  <div class="col-md-2 col-sm-4 col-xs-3 small-width">
    <div class="circle">
      <h2>2</h2>
      <h6>الاتقان</h6>
    </div>
  </div>
  <div class="col-md-2 col-sm-4 col-xs-3 small-width">
    <div class="circle">
      <h2>3</h2>
      <h6>الخصوصية</h6>
    </div>
  </div>
  <div class="col-md-2 col-sm-4 col-xs-3 small-width">
    <div class="circle">
      <h2>4</h2>
      <h6>الشراكة</h6>
    </div>
  </div>
  <div class="col-md-2 col-sm-4 col-xs-3 small-width">
    <div class="circle">
      <h2>5</h2>
      <h6>التميز</h6>
    </div>
  </div>
  <div class="col-md-2 col-sm-4 col-xs-3 small-width">
    <div class="circle">
      <h2>6</h2>
      <h6>الاحترافية</h6>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12" style="margin-top: 60px">
      <div class="beside">
        <img
          class="stratigic-icon"
          src="assets/images/target (2).svg"
          alt="card-icon"
        />
        <h3 style="margin-top: 15px" class="text-dark">أهداف استراتيجية</h3>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12" style="margin-bottom: 25px">
      <div
        *ngFor="let item of goals; let i = index"
        class="card"
        style="background: #fbfbfb"
      >
        <div class="beside">
          <h6 class="stratigic-number">{{ i + 1 }}</h6>
          <h6 style="margin-top: 15px">
            {{ item.title }}
          </h6>
        </div>
        <p
          *ngFor="let desc of item?.description?.split('.')"
          style="padding: 0px 15px"
        >
          {{ desc }}
        </p>
      </div>
    </div>
  </div>
</div>
