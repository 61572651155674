<!-- Footer -->
<footer class="page-footer font-small mdb-color">
  <!-- Footer Links -->
  <div class="row color-style">
    <div class="container text-center text-md-left">
      <!-- Footer links -->
      <div class="row text-center text-md-left mt-3 pb-3">
        <!-- kellana logo and social buttons -->
        <div class="col-md-3 col-lg-3 col-xs-12 text-left mt-3 mx-auto">
          <!-- kellana logo -->
          <a class="footer-brand" routerLink="/home"
            ><img src="assets/images/logo.PNG" alt="kellana-logo"
          /></a>

          <!-- Social buttons -->
          <!-- <h6
            class="text-uppercase mb-4 font-weight-bold"
            style="margin-top: 35px"
          >
            شبكات التواصل الإجتماعية
          </h6>
          <div class="text-center social">
            <ul class="list-unstyled list-inline">
              <li *ngFor="let item of SocialLinks">
                <a href="{{ item.value }}" target="_blank">
                  <i class="fa fa-{{ item.type?.name }}"></i>
                </a>
              </li>
            </ul>
          </div> -->
        </div>

        <!-- links -->
        <div class="col-md-2 col-lg-2 col-xs-12 mx-auto mt-3 text-left">
          <h6 class="text-uppercase mb-4 font-weight-bold">روابط تهمك</h6>
          <ul class="edit">
            <li class="mb-2">
              <a
                href="https://www.moh.gov.sa/Pages/Default.aspx"
                target="_blank"
                >وزارة الصحة
              </a>
            </li>
            <li class="mb-2">
              <a href="https://www.scot.gov.sa/" target="_blank"
                >المركز السعودي لزراعة الأعضاء</a
              >
            </li>
          </ul>

          <!-- <div class="subscribe_form" >
                        <form [formGroup]="SubscribeForm" style="margin: 0px;width:100%">
                            <input type="text" formControlName="email" placeholder="القائمة البريدية" class="form-control subscribe" style="border-radius: 0px 7px 7px 0px !important;">
                            <button class="subscribe-btn" (click)="PostSubscribeEmail()" > <i class="fa fa-plus" style="margin: 0px 5px;"></i>أضف</button>
                        </form>
                    </div>  -->
        </div>

        <!-- about kellana -->
        <!-- <div class="col-md-3 col-lg-3 col-xs-12 mx-auto mt-3 text-left">
          <h6 class="text-uppercase mb-4 font-weight-bold">عن الجمعية</h6>
          <ul class="edit">
            <li class="mb-2" *ngFor="let item of governance_list">
              <a routerLink="about/governance/{{ item.id }}" target="blank">{{
                item.title
              }}</a>
            </li>
          </ul>
        </div> -->

        <!-- contact us -->
        <div class="col-md-3 col-lg-3 col-xs-12 mx-auto mt-3 text-left">
          <h6 class="text-uppercase mb-4 font-weight-bold">الفرع الرئيسي</h6>
          <p><i class="fa fa-home"></i> {{ contactData?.address }}</p>
          <p><i class="fa fa-envelope"></i> {{ contactData?.email }}</p>
          <p class="Numbers-font">
            <i class="fa fa-phone"></i> {{ contactData?.mobile }}
          </p>
        </div>

        <!-- mail Newsletr -->
        <div class="col-md-4 col-lg-4 col-xs-12 mx-auto mt-3 text-left">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h6 class="text-uppercase mb-4 font-weight-bold">
                القائمة البريدية
              </h6>
              <p>
                أدخل عنوان بريدك الإلكتروني أدناه للاشتراك في القائمة البريدية
              </p>
            </div>

            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 subscribe-div"
              [formGroup]="SubscribeForm"
            >
              <input
                formControlName="email"
                type="email"
                class="subscribeTerm"
                placeholder="البريد الالكتروني"
              />
              <button
                type="submit"
                (click)="PostSubscribeEmail()"
                class="subscribeButton"
              >
                <i class="fas fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- visitors count -->
      <!-- <div class="col-md-6 col-lg-8 col-sm-12 col-xs-12">
                <div class="visitors-wrapper">
                    <span class="title">عدد الزوار</span>
                    <div class="counter">
                        <i class="fa fa-user"></i>
                        <span id="visitors" class="visitors">{{visitors}}</span>
                    </div>
                </div>
            </div> -->
      <hr />
    </div>
  </div>

  <!-- Footer Links -->

  <!-- Grid row -->
  <div class="row d-flex align-items-center" style="background-color: black">
    <div class="col-md-12 col-lg-12">
      <!--Copyright-->
      <p class="text-center" style="color: white">
        <a class="footer-link">
          جميع الحقوق محفوظة لجمعية الأمير فهد بن سلمان الخيرية لرعاية مرضي الفشل الكلوي      (لكلانا)   
        </a>
        <!-- {{'FOOTER.COPY_RIGHT'|translate}} <a class="footer-link" href="https://pentavalue.com/en">{{'FOOTER.PENTAVALUE'|translate}}</a> {{'FOOTER.ALL_RIGHT'|translate}} -->
      </p>
    </div>
  </div>
</footer>
<!-- Footer -->
<!-- <app-donate-popup *ngIf="myprofile" (closePopup)='closeProfile($event)'> </app-donate-popup> -->
