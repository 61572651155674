import { Component, OnInit } from '@angular/core';
import { PartenersService } from '../../services/parteners.service';


@Component({
  selector: 'app-communication-companies',
  templateUrl: './communication-companies.component.html',
  styleUrls: ['./communication-companies.component.scss']
})
export class CommunicationCompaniesComponent implements OnInit {

  // communication companies list empty object
  communicationCompanies_list: any[] = [];

  pageSize: number = 10;
  totalItems: number = 0;
  currentPage: number = 1;

  constructor(
    private service: PartenersService
  ) { }

  ngOnInit(): void {
    this.getCommunicationCompanies();
  }

  // get Communication Companies list data from api
  getCommunicationCompanies() {
    this.service.getCommunicationCompaniesList(this.currentPage).subscribe((res: any) => {
      // push Communication Companies data to object
      this.communicationCompanies_list = res.content;
    })
  }

}
