<div class="full-width">
    <div class="row page-title">
      <div class="col-md-6 col-sm-6 col-xs-12">
        <h3> أمراض الكلي و طرق الوقاية منها</h3>
      </div>
  
      <div class="col-md-6 col-sm-6 col-xs-12">
        <nav aria-label="breadcrumb" class="titles">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
            <li class="breadcrumb-item" style="padding-left: 0px">
              <a routerLink="../learn-more" style="padding-right: 10px">أمراض الكلي و طرق الوقاية منها</a>
            </li>
          </ol>
        </nav>
      </div>
    </div>
</div>


<div class="container ">

    <div class="row mar">
        <div class="col-md-12 col-sm-12 col-xs-12" style="    text-align: right;">
            <h2 class="title">{{info.title}}</h2>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
            <p>{{info.description}}</p>
        </div>

    </div>


</div>


<div class="container">
    <div class="row">

        <div class="col-md-6 col-sm-12 col-xs-12 mar-b" *ngFor="let item of learn_list">
            <div class="row">

                <div class="col-md-3 col-sm-3 col-xs-3 center">
                   <img src="{{item.image}}">
                </div>
                <div class="col-md-9 col-sm-9 col-xs-9">
                    <h4 class="title-position">{{item.title}}</h4>
                    <p>{{item.description}}</p>
                </div>
                 
            </div>
        </div>

    </div>
</div>