<div class="full-width">
  <div class="row page-title">
    <div class="col-md-6 col-sm-6 col-xs-12">
      <h3>صحتنا في كلانا</h3>
    </div>

    <div class="col-md-6 col-sm-6 col-xs-12">
      <nav aria-label="breadcrumb" class="titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
          <li class="breadcrumb-item" style="padding-left: 0px">
            <a routerLink="about/campaign" style="padding-right: 10px">
              صحتنا في كلانا
            </a>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <!-- slider -->
    <!-- <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="swiper">
        <div class="swiper-container" *ngIf="slides.length" [swiper]="config">
          <div class="swiper-wrapper">
            <div *ngFor="let slide of slides; index as i" class="swiper-slide">
              <div class="slide-img">
                <img [src]="slide.image" />
              </div>
            </div>
          </div>
          <div class="swiper-pagination" slot="pagination"></div>

          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </div> -->

    <div
      *ngFor="let item of campaigns"
      class="col-md-6 col-sm-12 col-xs-12 mt-2"
    >
      <div class="card">
        <h6>{{ item.title }}</h6>
        <p [innerHtml]="item.content"></p>
      </div>
    </div>
  </div>
</div>

<div class="row mt-2">
  <div class="card padd" style="height: 100% !important">
    <div class="row">
      <div class="col-md-2 col-sm-2 col-xs-12">
        <h6 style="font-size: 19px">المستهدفون</h6>
        <p class="numbers">3,286,236</p>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-12">
        <h5>طالب وطالبة يشكلون 70% من اجمالي طلاب التعليم</h5>
        <p>حسب الإحصائية الأخيرة لوزارة التربية والتعليم</p>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-12"></div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-6 col-sm-12 col-xs-12 mt-2">
      <div class="card large-cards">
        <h6>خطوات العمل</h6>

        <p>- اختيار هوية الحملة .</p>
        <p>
          - تقسيم الحملة على أربع مراحل كل مرحلة تستهدف مناطق تعليمية متنوعة.
        </p>
        <p>- موعد بدء الحملة جمادي الأول من عام 1435 هجري .</p>
        <p>- تحديد المدارس المشاركة لكل مرحلة .</p>
        <p>
          - عقد ورش عمل من مختصي الجمعية مع منسقي الحملة لكل مدرسة ( معلم علوم /
          مرشد طلابي ) .
        </p>
        <p>- اقامة حفل تدشين الحملة و تكريم الرعاة .</p>
        <p>- اقامة حفل اختتام و تكريم المميزين في الحملة .</p>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12 mt-2">
      <div class="card large-cards">
        <h6>البرامج المقترحة في المرحلة الثانية ( صحتنا في كلانا)</h6>
        <p>- حفل تدشين</p>
        <p>- حصر حالات مرضى الفشل الكلوي و الامراض المسببة له .</p>
        <p>- التوعية عن امراض الفشل الكلوي و الامراض المسببة له .</p>
        <p>- اقامة ورش عمل    للعاملين على هذه الحملة -</p>
        <p>
          - التوعية عن الفشل الكلوي و الامراض المؤدية له ( محاضرات توعيه - زيارة
          المصابين بامراض الكلي ) لجميع الطلاب .
        </p>
        <p>- الخيمة التوعوية ( المولات - المهرجانات - الصحة المدرسية ).</p>
        <p>
          - دوري كرة قدم تحت شعار حملة صحتنا في كلانا للمرحلة الابتدائية ( بنين
          ).
        </p>
        <p>- مسابقة كرة اليد للمرحلة المتوسطة و الثانوية(بنين).</p>
        <p>- سباق المارثون تحت شعار الحملة لكافة المجتمع ( بنين ) .</p>
        <p>- تنفيذ برنامج كشفي للمرحلة المتوسطة ( بنين ).</p>
      </div>
    </div>
  </div>
</div>

<!-- <div class="row mt-2">
  <div class="card padd" style="height: 100% !important">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h6 style="font-size: 19px; margin-bottom: 5px">أدوات الحملة</h6>
      </div>
    </div>
  </div>
</div> -->

<div class="container">
  <div class="row">
    <div
      *ngFor="let item of campaigns_tools; let i = index"
      class="col-md-6 col-sm-12 col-xs-12 mt-2"
    >
      <div class="card small-cards">
        <h6>
          <span>{{ i + 1 }}</span
          >{{ item.title }}
        </h6>
        <p [innerHtml]="item.content"></p>
      </div>
    </div>
  </div>
</div>

<div class="row download-section">
  <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center">
    <p class="download-text">
      اذا اردت معرفة المزيد في صحتنا في كلانا يمكنك تحميل الملف
    </p>
    <a class="download-btn" (click)="downloadPDFFile()">تحميل الملف الان</a>
  </div>
</div>
