import { Component, OnInit } from '@angular/core';
import {CommonGeneralService} from '../services/common-general.service'


@Component({
  selector: 'app-learn-more',
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.scss']
})
export class LearnMoreComponent implements OnInit {

 
  learn_list;
  info;
  constructor(private service: CommonGeneralService) { }

  ngOnInit(): void {

    this.service.getinfoRightslearnlist().subscribe((res:any)=>{
      this.info = res.content[1]
      console.log("Learn More res :::::::::::", this.info)

    })

    this.service.getlearnmorelist().subscribe((res:any)=>{
      this.learn_list = res.content ;
      console.log("Learn More list res :::::::::::", this.learn_list)
    })
  }

}
