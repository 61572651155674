import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistics-cards',
  templateUrl: './statistics-cards.component.html',
  styleUrls: ['./statistics-cards.component.scss']
})

export class StatisticsCardsComponent implements OnInit {
  @Input() statistics;

  constructor() { }

  ngOnInit(): void {

  }

}
