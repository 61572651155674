import { Component, OnInit } from "@angular/core";

import { AboutService } from "src/app/about/services/about.service";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
  whoWeAre: any = {};
  management: any = {};
  formers: any = {};
  constructor(private AboutService: AboutService) {}

  ngOnInit(): void {
    this.AboutService.getWhoWeAre().subscribe((res: any) => {
      this.whoWeAre = res.content;
    });
    this.AboutService.management().subscribe((res: any) => {
      this.management = res.content;
    });
    this.AboutService.formers().subscribe((res: any) => {
      this.formers = res.content;
    });
  }
}
