import { BaseHttpService } from "src/app/services/base-http.service";
import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ProgramsService {
  header: HttpHeaders = new HttpHeaders();

  constructor(private http: BaseHttpService) { }

  // get programs
  getPrograms() {
    return this.http.post("/api/cms", {
      module_id: 2874,
      application_id: 188,
      lang_id: 2,
    });
  }

  // get one program by id
  getProgramById(programID) {
    let setHeaders = this.header.set("access-token", localStorage.getItem("login_token"));
    let programByIDObject = {
      id: programID,
      module_id: 2874,
      application_id: 188,
      lang_id: 2,
    };
    return this.http.post("/api/cms/show", programByIDObject, localStorage.getItem("login_token") ? { headers: setHeaders } : {});
  }

  // call api to get Programs status
  getProgramsStatus() {
    let setHeaders = this.header.set("email", localStorage.getItem("email"));
    return this.http.post(
      "/api/cms/myPrograms",
      {
        module_id: 2874,
        application_id: 188,
        lang_id: 2,
      },
      { headers: setHeaders }
    );
  }

  // call api to get Programs status search
  getProgramsStatusSearch(search) {
    let setHeaders = this.header.set("email", localStorage.getItem("email"));
    let user_id = localStorage.getItem("User_ID");
    return this.http.post(
      "/api/cms/myPrograms",
      {
        module_id: 2874,
        application_id: 188,
        created_by: user_id,
        lang_id: 2,
        title: search,
      },
      { headers: setHeaders }
    );
  }
}
