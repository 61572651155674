import { HomeService } from './../../../general-pages/pages/home/home.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-img-title-card',
  templateUrl: './img-title-card.component.html',
  styleUrls: ['./img-title-card.component.scss']
})
export class ImgTitleCardComponent implements OnInit {

  partners_list: any = {};

  constructor(
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.homeService.getHomeContent().subscribe((res: any) => {
      if (res.content) {
        const data = res.content;
        for (let i = 0; i < data.length; i++) {
          if (data[i].title == 'شركاؤنا') {
            this.partners_list = data[i].data;
          }
        }
      }
    })
  }

}
