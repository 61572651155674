import { Component, OnInit } from "@angular/core";

import { AboutService } from "../../services/about.service";

@Component({
  selector: "app-directors",
  templateUrl: "./directors.component.html",
  styleUrls: ["./directors.component.scss"],
})
export class DirectorsComponent implements OnInit {
  file: any = {};
  directors;
  constructor(private AboutService: AboutService) { }

  ngOnInit(): void {
    this.AboutService.getDirectors().subscribe((res) => {
      console.log(res);
    });
  }

  onFileChange(event) {
    this.file = event.target.files[0];
  }

}
