import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circles4',
  templateUrl: './circles4.component.html',
  styleUrls: ['./circles4.component.scss']
})
export class Circles4Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
