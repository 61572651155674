<div class="row">
    <div class="col-md-4 col-sm-12 col-xs-12">
        <a >
            <div class="card">
                <div class="card-img-top" style="background-image: url(assets/images/logo2.png);"></div>
                <div class="card-body ">
                    <div class="row">
                        <div class="col-md-3 col-sm-4 xol-xs-4">

                            <time datetime="" class="icon">
                                <!-- <em>{{event.created_at | dateFormatter: 'ddd':'ar'}}</em>
                                <strong>{{event.created_at | dateFormatter: 'MMM':'ar'}}</strong>
                                <span>{{event.created_at | dateFormatter: 'D':'ar'}}</span> -->

                                <em>أغسطس </em>
                                <strong>30</strong>
                                <span>2021 </span> 
                            </time>
                            
                        </div>
                        <div class="col-md-9 col-sm-9 col-xs-9 ">
                            <div class="event-desc text-left">
                                <h4 class="card-title">
                                    43 مليون تكلفة 78 ألف جلسة غسيل كلى سنويا تنفذها “كلانا”
                                </h4>
                                <p> <i class="fa fa-map-marker"></i>&nbsp;  </p>
                                <a class="more">{{'HOME.VIEW_DETAILS'|translate}}
                                    <i class="fa fa-arrow-circle-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
        <a >
            <div class="card">
                <div class="card-img-top" style="background-image: url(assets/images/date2.png);"></div>
                <div class="card-body ">
                    <div class="row">
                        <div class="col-md-3 col-sm-4 xol-xs-4">

                            <time datetime="" class="icon">
                                <!-- <em>{{event.created_at | dateFormatter: 'ddd':'ar'}}</em>
                                <strong>{{event.created_at | dateFormatter: 'MMM':'ar'}}</strong>
                                <span>{{event.created_at | dateFormatter: 'D':'ar'}}</span> -->

                                <em>مارس </em>
                                <strong>31</strong>
                                <span>2010 </span> 
                            </time>
                            
                        </div>
                        <div class="col-md-9 col-sm-9 col-xs-9 ">
                            <div class="event-desc text-left">
                                <h4 class="card-title">
                                    توقيع اتفاقية مع جمعية بصر للجميع لإجراء فحوصات النظر لمرضى الفشل الكلوي
                                </h4>
                                <p> <i class="fa fa-map-marker"></i>&nbsp;  </p>
                                <a class="more">{{'HOME.VIEW_DETAILS'|translate}}
                                    <i class="fa fa-arrow-circle-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
        <a >
            <div class="card">
                <div class="card-img-top" style="background-image: url(assets/images/date3.jpeg);"></div>
                <div class="card-body ">
                    <div class="row">
                        <div class="col-md-3 col-sm-4 xol-xs-4">

                            <time datetime="" class="icon">
                                <!-- <em>{{event.created_at | dateFormatter: 'ddd':'ar'}}</em>
                                <strong>{{event.created_at | dateFormatter: 'MMM':'ar'}}</strong>
                                <span>{{event.created_at | dateFormatter: 'D':'ar'}}</span> -->

                                <em>مارس </em>
                                <strong>11</strong>
                                <span>2010 </span> 
                            </time>
                            
                        </div>
                        <div class="col-md-9 col-sm-9 col-xs-9 ">
                            <div class="event-desc text-left">
                                <h4 class="card-title">
                                    بمناسبة اليوم العالمي للكلى وفد من شركة ميدغلف يقوم بزيارة جمعية كلانا</h4>
                                <p> <i class="fa fa-map-marker"></i>&nbsp;  </p>
                                <a class="more">{{'HOME.VIEW_DETAILS'|translate}}
                                    <i class="fa fa-arrow-circle-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>