import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AuthenticationService } from "../../services/authentication.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})

export class ResetPasswordComponent implements OnInit {

  emailForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
  });

  constructor(
    private route: Router,
    private AuthenticationService: AuthenticationService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void { }

  submitForm() {
    this.AuthenticationService.forgetPasswordForm(this.emailForm.value).subscribe((res: any) => {
      if (res.status.code == 200){
        this.toaster.success('تم بنجاح');
      }
      this.route.navigateByUrl("/Email-Verification");
      localStorage.setItem("email", this.emailForm.value.email);
      localStorage.setItem('login_token', res.content.api_token);
    },
      (err) => {
        this.toaster.error(err.error.status.error_details);
      }
    );
  }
}
