

    <div class="row statistics-title">
        <div class="col-md-12" style="text-align: center;">
            <h3>خدمات صحية واجتماعية برؤية إنسانية خيرية واعية</h3>
        </div>
    </div>

    <div class="row statistics-container">
             
        
        <div class="col-md-3 no-padding">
            <div class="statistics-box main-colour" style="border-radius: 0px 0px 7px 0px;">
                 <img src="assets/images/statistics4.png">
                 <h1 class="number">156</h1>
                 <p class="statistics-text">جلسة غسيل دموي يحتاجها مريض الفشل الكلوي سنوياً</p>
            </div>
        </div>

        <div class="col-md-3 no-padding border-right main-colour2">
            <div class="statistics-box">
                 <img src="assets/images/statistics3.png">
                 <h1 class="number">446</h1>
                 <p class="statistics-text">مريض فشل كلوي يغسل في الجمعية</p>
            </div>
        </div>

        <div class="col-md-3 no-padding border-right">
            <div class="statistics-box main-colour" >
                 <img src="assets/images/statistics2.png">
                 <h1 class="number">28</h1>
                 <p class="statistics-text">مركز غسيل تتعامل معهم الجمعية على مستوى المملكة</p>
            </div>
        </div>

        <div class="col-md-3 no-padding border-right">
            <div class="statistics-box main-colour2" style="border-radius: 0px 0px 0px 7px;">
                 <img src="assets/images/statistics1.png">
                 <h1 class="number">115000</h1>
                 <p class="statistics-text">ريال التكلفة السنوية لمريض الفشل الكلوي بالجمعية</p>
            </div>
        </div>



    </div>

