import { Router } from '@angular/router';
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-service-card",
  templateUrl: "./service-card.component.html",
  styleUrls: ["./service-card.component.scss"],
})
export class ServiceCardComponent implements OnInit {
  @Input() services_list;

  @Input() type: string;

  constructor(private router: Router) { }

  ngOnInit(): void { }

  navigateToEditPage(id: number, formId?) {
    this.router.navigateByUrl((this.type == 'program' ? '/forms/form-edit/' + formId + '/' : this.type == 'membership' ? '/about/members-edit/' : '/volunteers/volunteer-edit/') + id)
  }
}
