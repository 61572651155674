<div class="home">
  <div class="container-fluid">
      <!-- programs slider -->
    <div class="row justify-content-center">
      <div class="col-md-10">
        <app-home-slider [slides]="slides"></app-home-slider>
      </div>
    </div>
    <!-- statistics -->
    <div class="row justify-content-center my-4 pt-4">
      <div class="col-md-10">
        <app-statistics-cards [statistics]="statistics"></app-statistics-cards>
      </div>
    </div>
    <!-- bg image -->
    <div class="row my-4 pt-4">
      <div class="col-md-12 bg-image"></div>
      <!-- <app-button-cards3 [programs]="programs"></app-button-cards3> -->
    </div>
    <!-- news -->
    <!-- <div class="row justify-content-center my-4 pt-4">
      <div class="col-md-10">
        <app-latest-news
          [news_list]="news_list"
          [news]="news"
        ></app-latest-news>
      </div>
    </div> -->
    <!-- waqf -->
    <div class="row justify-content-center my-4 pt-4">
      <div class="col-md-10">
        <app-home-waqf [waqf_list]="waqf_list"></app-home-waqf>
      </div>
    </div>

    <div class="row justify-content-center my-4 pt-4">
      <div class="col-md-10">
            <app-two-cards></app-two-cards>
      </div>
    </div>

    
  </div>
  <!-- <div class="container">
    <app-home-management-heads
      [managers_list]="managers_list"
    ></app-home-management-heads>
  </div> -->

  <!-- <div
    class="container-fluid"
    style="background-color: #fbfbfb; padding: 40px"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-6">
          <h3 class="main-title">شركاؤنا</h3>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6">
          <a class="all-btn" routerLink="/partener/ministry">الكل</a>
        </div>
      </div>
      <app-img-title-card></app-img-title-card>
    </div>
  </div> -->
</div>
