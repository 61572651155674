import { Component, OnInit } from '@angular/core';
import { ArticlesService } from 'src/app/articles/services/articles.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  
  eventList: any[];
  articles: any[];
  initiatives: any[];
  constructor(private activeRoute: ActivatedRoute,
              private newsService: ArticlesService, 
          ) { }

  ngOnInit(): void {
    /* this.searchData(this.activeRoute.snapshot.queryParams['query']); */
    this.activeRoute.queryParams.subscribe(res => {
      this.searchData(this.activeRoute.snapshot.queryParams['query'])
    })
  }

  searchData(query) {
    this.newsService.getSearchNews(query).subscribe((res:any) => {
      this.articles = res.content;
      // console.log("Search Results=========>",this.initiatives)
    });

    
  }

}
