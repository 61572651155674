import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';
import { HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  header: HttpHeaders = new HttpHeaders();


  constructor(private baseHttpService: BaseHttpService) { }



  GetMenuItems() {
    let headerNotification = this.header.set("robo", 'web')

    return this.baseHttpService.post('/api/menu', {
      application_id: 188,
      lang_id : 2

    }, { headers: headerNotification })

  }

  // get governance
  getGovernance() {

    return this.baseHttpService.post('/api/cms', {
      module_id: 2891,
      lang_id: 2,
      application_id: 188,
    })
  }

  // get one governance by id
  getOneGovernance(id) {
    let headerNotification = this.header.set('Access-Control-Allow-Origin', '*')
    return this.baseHttpService.post('/api/cms/show', {
      application_id: 188,
      module_id: 2891,
      lang_id: 2,
      id: id,
    }, { headers: headerNotification })
  }

}
