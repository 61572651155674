
<div class="full-width">
    <div class="row page-title">

      <div class="col-md-6 col-sm-6 col-xs-12">
        <h3>
            التعريف بالجمعية
        </h3>
      </div>

      <div class="col-md-6 col-sm-6 col-xs-12">
        <nav aria-label="breadcrumb" class="titles">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
              <li class="breadcrumb-item" style="padding-left: 0px;"><a routerLink="about/about-us" style="padding-right: 10px;"> التعريف بالجمعية </a></li>
            </ol>
        </nav>
      </div>

    </div>
</div>

<div class="about">
    <div class="container">

        <div class="row">
           <div class="col-md-12 col-sm-12 col-xs-12">


                <div class="tabs-container">

                    <input style="display: none;" type="radio" id="tab2" name="tab" checked>
                    <label class="tab-label" for="tab2">إدارة الجمعية</label>
                    <input style="display: none;" type="radio" id="tab1" name="tab" >
                    <label class="tab-label" for="tab1">أمناء سابقين للجمعية</label>

                    <div class="content-container clearfix">



                        <div class="content" id="c2">
                            <div class="container">

                                 <p>first content </p>

                            </div>
                        </div>

                        <div class="content" id="c1">
                            <div class="container">





                            </div>
                        </div>




                    </div>

                </div>


           </div>
        </div>

        <div class="row">
            <!-- <div class="col-md-12 col-sm-12 col-xs-12 identification text-left no-padding">

                <h6 class="card-body bold" [innerHTML]="whoWeAre?.content">
                </h6>
            </div> -->


                <div class="col-md-3 col-sm-12 col-xs-12 pull-left">
                    <div class="card text-center">
                        <img class="" src="{{vision?.photo}}" height="150" >
                        <!-- <div class="img-responsive" [ngStyle]="{'background-image': 'url('+ vision?.photo +')'}"></div> -->
                        <h2 class="main-title text-center">{{vision?.name}}</h2>
                        <p class="card-body" [innerHTML]="vision?.content">
                        </p>
                    </div>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12  pull-left">
                    <div class="card text-center">
                        <img class="" src="{{mission?.photo}}" height="150" >
                        <!-- <div class="img-responsive" [ngStyle]="{'background-image': 'url('+ mission?.photo +')'}"></div> -->
                        <h2 class="main-title text-center">{{mission?.name}}</h2>
                        <p class="card-body" [innerHTML]="mission?.content">
                        </p>
                    </div>
                </div>


                <div class="col-md-3 col-sm-12 col-xs-12 pull-left text-center">
                    <div class="card text-center">
                        <img class="" src="{{goals?.photo}}" height="150" >
                        <!-- <div class="img-responsive" [ngStyle]="{'background-image': 'url('+ goals?.photo +')'}"></div> -->
                        <h2 class="main-title text-center">{{goals?.name}}</h2>
                        <p class="card-body right-text" [innerHTML]="goals?.content">
                        </p>
                    </div>
                </div>

                <div class="col-md-3 col-sm-12 col-xs-12 pull-left">
                    <div class="card text-center">
                        <img class="" src="{{vision?.photo}}" height="150" >
                        <!-- <div class="img-responsive" [ngStyle]="{'background-image': 'url('+ vision?.photo +')'}"></div> -->
                        <h2 class="main-title text-center">{{vision?.name}}</h2>
                        <p class="card-body" [innerHTML]="vision?.content">
                        </p>
                    </div>
                </div>


        </div>


        <div class="row" style="margin-top: 40px;">
            <div class="col-md-12" >
               <h3 class="main-title">أول رئيس لمجلس الإدارة</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-md-1 col-sm-1 col-xs-12 pull-left"></div>
            <div class="col-md-5 col-sm-5 col-xs-12 pull-left">
                <img class="about-img" src="assets/images/king.jpg">
                <h6 class="names">خادم الحرمين الشريفين الملك سلمان بن عبدالعزيز</h6>
            </div>
            <div class="col-md-5 col-sm-5 col-xs-12 pull-left">
                <img class="about-img" src="assets/images/minister.jpg">
                <h6 class="names">صاحب السمو الملكي الأمير عبدالعزيز بن سلمان</h6>
                <small class="small-text">رئيس مجلس الادارة</small>
            </div>
            <div class="col-md-1 col-sm-1 col-xs-12 pull-left"></div>

        </div>

        <div class="row" style="margin-top: 40px;">
            <div class="col-md-12" style="text-align: center;">
               <h3 class="main-title">إدارة الجمعية</h3>
               <h6 style="text-align: center;">يتم إدارة الجمعية من خلال الجمعية العمومية بالإضافة إلى مجلس الإدارة و بعض اللجان المتعددة</h6>
               <div style="display: flex;justify-content: center;">
                  <button class="red-btn">اللجنة التنفيذية</button>
                  <button class="red-btn">اللجنة الصحية والاجتماعية </button>
                  <button class="red-btn"> لجنة تنمية الموارد </button>
                  <button class="red-btn">اللجنة الإعلامية </button>
                  <button class="red-btn"> اللجنة الشرعية</button>

               </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12"></div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <img class="about-img" src="assets/images/king.jpg">
                <h6 class="names">الأستاذ الدكتور عبد الله بن حسن الدغيثر</h6>
                <small class="small-text">الرئيس التنفيذي</small>

            </div>
            <div class="col-md-4 col-sm-4 col-xs-12"></div>

        </div>

        <div class="row" style="margin-top: 40px;">
            <div class="col-md-12" >
               <h3 class="main-title">أمناء سابقين للجمعية</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-md-1 col-sm-1 col-xs-12 pull-left"></div>
            <div class="col-md-5 col-sm-5 col-xs-12 pull-left">
                <img class="about-img" src="assets/images/king.jpg">
                <h6 class="names">صاحب السمو الملكي الأمير أحمد بن سلمان</h6>
            </div>
            <div class="col-md-5 col-sm-5 col-xs-12 pull-left">
                <img class="about-img" src="assets/images/minister.jpg">
                <h6 class="names">صاحب السمو الملكي الأمير فهد بن سلمان</h6>
            </div>
            <div class="col-md-1 col-sm-1 col-xs-12 pull-left"></div>

        </div>


    </div>
</div>


