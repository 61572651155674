<div class="full-width">
  <div class="row page-title">
    <div class="col-md-6 col-sm-6 col-xs-12">
      <h3>العضوية</h3>
    </div>

    <div class="col-md-6 col-sm-6 col-xs-12">
      <nav aria-label="breadcrumb" class="titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
          <li class="breadcrumb-item" style="padding-left: 0px">
            <a routerLink="about/members" style="padding-right: 10px"
              >العضوية</a
            >
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<div class="row">
  <div
    class="col-md-12 col-sm-12 col-xs-12 section-title-padding"
    style="background-color: #fbfbfb; margin-top: 20px"
  >
    <h4 class="section-title">شروط الحصول على عضوية</h4>
  </div>
</div>

<div class="container">
  <!-- <div class="row" style="margin:30px 0px">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <h3 class="main-title">   نوع العضوية</h3>
                </div>
            </div> -->

  <div class="row" style="margin-bottom: 40px">

    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="card card2">
        <div class="row" style="width: 100%;">
          <div
            class="col-md-4 col-sm-12 col-xs-12"
            style="padding: 20px; text-align: center"
          >
            <h6 class="names" style="margin-top: 60px; color: #ca2326">
              الشروط الواجب توفرها في عضو الجمعية:
            </h6>
          </div>
          <div class="col-md-8 col-sm-12 col-xs-12 txt-bg">
            <p>أن يكون سعودي الجنسية</p>
            <p>أن يكون قد أتم الثامنة عشرة من عمره</p>
            <p>أن يكون كامل الأهلية المعتبرة شرعاً</p>
            <p>
              أن يكون غير محكوم عليه بإدانة في جريمة مخلة بالشرف أو الأمانة ما
              لم يكن قد رد إليه اعتباره
            </p>
            <p>أن يكون قد سدد الحد الأدنى للاشتراك السنوي</p>
          </div>

        </div>
      </div>
    </div>

  

    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="card card2">
        <div class="row" style="width: 100%;">
          <div
            class="col-md-4 col-sm-12 col-xs-12"
            style="padding: 20px; text-align: center"
          >
            <h6 class="names" style="margin-top: 60px; color: #ca2326">
              يفقد العضو عضويته بالجمعية في إحدى الحالات الآتية :
            </h6>
          </div>
          <div class="col-md-8 col-sm-12 col-xs-12 txt-bg">
            <p>الوفاة</p>
            <p>الانسحاب من الجمعية بطلب كتابي</p>
            <p>إذا فقد شرطاً من شروط العضوية</p>
            <p>
              إذا ألحق عن عمد أضراراً بالجمعية جسيمة سواء كانت مادية أو معنوية و
              يعود تقدير ذلك لمجلس الإدارة
            </p>
            <p>
              إذا تأخر عن تسديد الاشتراك لمدة (ستة شهور) من بداية السنة المالية
              للجمعية بعد إخطاره بخطاب على عنوانه المدون
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<div class="row">
  <div
    class="col-md-12 col-sm-12 col-xs-12 section-title-padding"
    style="background-color: #fbfbfb; margin-top: 20px"
  >
    <h4 class="section-title">أنواع العضويات</h4>
  </div>
</div>

<div class="container" *ngIf="memberShip">
  <div class="row" style="margin-bottom: 40px">
    <div
      *ngFor="let item of memberShip"
      class="col-md-3 col-sm-6 col-xs-12"
      style="text-align: center"
    >
      <div class="card">
        <img class="" src="{{ item.image }}" />
        <div class="card-body">
          <h6 class="names" style="text-align: center">
            {{ item.title }}
          </h6>
          <p class="member-paragraph">
            {{ item.content | textFormatter }}
          </p>
        </div>
      </div>
    </div>

    <!-- <div class="col-md-3 col-sm-6 col-xs-12" style="text-align: center">
      <div class="card">
        <img class="" src="assets/images/member1.jpg" />
        <div class="card-body">
          <h6 class="names" style="text-align: center">
            {{ memberShip[0]?.title }}
          </h6>
          <p class="member-paragraph">
            {{
              "و هو العضو الذي شارك في تأسيس الجمعية أو انضم إليها بعد تأسيسها بناء على دعوة من مجلس الإدارة ، ويكون لهذا العضو حق حضور اجتماعات الجمعية العمومية فور انضمامه، كما يكون له حق التصويت على قراراتها وترشيح نفسه لعضوية مجلس الإدارة وذلك بعد مضي سنة من تاريخ انضمامه للجمعية ويدفع اشتراكاً سنوياً قدره ( 1000 ) ريال كحد أدنى ."
            }}
          </p>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-xs-12" style="text-align: center">
      <div class="card">
        <img class="" src="assets/images/member2.jpg" />

        <div class="card-body">
          <h6 class="names" style="text-align: center">عضو منتسب</h6>
          <p class="member-paragraph">
            هو العضو الذي يطلب الانتساب إلى عضوية الجمعية و يقبل ذلك مجلس
            الإدارة بعد تحقق الشروط المنصوص عليها بالمادة رقم ( 4 ) عدا شرط السن
            ، و يجوز حضوره اجتماعات الجمعية العمومية دون التصويت على قراراتها و
            لا الترشيح لعضوية مجلس الإدارة و يدفع اشتراكاً سنوياً مخفضاً مقداره
            ( 200 ) ريال كحد أدنى .
          </p>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-xs-12" style="text-align: center">
      <div class="card">
        <img class="" src="assets/images/member3.jpg" />
        <div class="card-body">
          <h6 class="names" style="text-align: center">عضو فخري</h6>
          <p class="member-paragraph">
            ج – عضو فخري : هو العضو الذي تمنحه الجمعية العمومية العضوية الفخرية
            بناء على اقتراح المجلس و ذلك نظير جهوده المادية أو المعنوية أو
            العلمية في سبيل تحقيق أهداف الجمعية و له عند حضوره اجتماعات الجمعية
            العمومية و مجلس الإدارة حق المناقشة دون التصويت أو الترشيح و لا يثبت
            بحضوره صحة الانعقاد.
          </p>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-xs-12" style="text-align: center">
      <div class="card">
        <img class="" src="assets/images/member4.jpg" />
        <div class="card-body">
          <h6 class="names" style="text-align: center">عضو شرف</h6>
          <p class="member-paragraph">
            هو العضو الذي يمنحه مجلس الإدارة عضوية الجمعية نظير ما قدمه لها من
            خدمات جليلة مادية كانت أم معنوية ساعدت الجمعية على تحقيق أهدافها و
            له حق حضور اجتماعات الجمعية العمومية ومناقشة ما يطرح فيها دون أن
            يكون له حق التصويت أو الترشيح لعضوية مجلس الإدارة . و لا يثبت بحضوره
            صحة الانعقاد .
          </p>
        </div>
      </div>
    </div> -->
  </div>

  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center">
      <button class="member-btn" routerLink="../../about/members-form">
        تقدم بطلبك الان
      </button>
    </div>
  </div>
</div>
