import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';
import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  constructor(private baseHttpService: BaseHttpService) { }

  // call api to get news
  getNews(currentPage = 1) {
    let baseNewsObject = {
      "module_id": 2865,
    }
    return this.baseHttpService.post('/api/cms', baseNewsObject, {
      params: {
        page: currentPage
      }
    });
  }

  // get one of news data by id
  getNewsById(articleID) {
    let articleByIDObject = {
      "id": articleID,
      "module_id": 2865
    }
    return this.baseHttpService.post('/api/cms/show', articleByIDObject,
      {});
  }

  // get news by title search
  getSearchNews(search) {
    let baseEventsObject = {
      "application_id": 25,
      "lang_id": 2,
      "module_id": 2865,
      "title": search
    }
    return this.baseHttpService.post('/api/cms', baseEventsObject, {
    });
  }

  // get news by advanced search
  getAdvancedSearchNews(searchData) {
    let baseEventsObject = {
      "application_id": 25,
      "lang_id": 2,
      "module_id": 2865,
      "title": searchData.name,
      "content": searchData.content,
      "from_date": searchData.from_date,
      "to_date": searchData.to_date
    }
    return this.baseHttpService.post('/api/cms', baseEventsObject, {
    });
  }

  // get Initiatives by title Search
  getSearchInitiatives(currentPage = 1, search) {
    let baseEventsObject = {
      "module_id": 489,
      "title": search
    }
    return this.baseHttpService.post('/api/initiatives', baseEventsObject, {
      params: {
        page: currentPage
      }
    });
  }

  // get Initiatives by advanced search
  getAdvancedSearchInitiatives(currentPage = 1, searchData) {
    let baseEventsObject = {
      "module_id": 489,
      "name": searchData.name,
      "content": searchData.content,
      "from_date": searchData.from_date,
      "to_date": searchData.to_date
    }
    return this.baseHttpService.post('/api/initiatives', baseEventsObject, {
      params: {
        page: currentPage
      }
    });
  }

  // get related news data
  getRelatedNews(articleID) {
    let baseNewsObject = {
      "module_id": 2865,
      "id": articleID,
    }
    return this.baseHttpService.post('/api/cms/related', baseNewsObject, {}
    );
  }

}
