<div [className]="('DIR' | translate) === 'rtl' ? 'rtl' : 'ltr'">
  <app-etaam-header></app-etaam-header>
  <app-popup></app-popup>
  <div class="page">
    <!-- <app-loader></app-loader> -->
    <app-spinner></app-spinner>

    <!-- <div>
             <button (click)="callApi()">Click</button>
            </div> -->
    <div class="container-fluid">
      <div class="row">
        <div class="right-side">
          <div class="social-btns">
            <ul class="social-media-container">

              <!-- <li class="social-media tiktok">
                <a
                  href="https://www.twitter.com"
                  target="_blank"
                  >Follow us on twitter</a
                >
                <div class="icon">
                  <i class="fab fa-tiktok"></i>
                </div>
              </li> -->

              <li
              *ngFor="let item of SocialLinks| slice:4:5"
              class="social-media {{ item.type?.name }}"
              style=" background-color: #231f20;"
            >
              <a href="{{ item.value }}" target="_blank"
                >Follow us on Twitter</a
              >
              <div class="icon" style="background-color: #000000;">
                <img src="assets/images/Twitter-new-logo.jpeg" class="twitter-img">
              </div>
            </li>

              <li
                *ngFor="let item of SocialLinks| slice:1:4"
                class="social-media {{ item.type?.name }}"
              >
                  <a href="{{ item.value }}" target="_blank">
                     Follow us on {{ item.type?.name }}
                  </a>
                  <div class="icon">
                    <i class="fab fa-{{ item.type?.name }}"></i>
                  </div>
              </li>

              <li
                *ngFor="let item of SocialLinks| slice:5:7"
                class="social-media {{ item.type?.name }}"
              >
                  <a href="{{ item.value }}" target="_blank">
                     Follow us on {{ item.type?.name }}
                  </a>
                  <div class="icon">
                    <i class="fab fa-{{ item.type?.name }}"></i>
                  </div>
              </li>

              <li class="social-media tiktok">
                <a
                  href="https://www.tiktok.com/@kellana5060?_t=8cMKHJrisci&_r=1"
                  target="_blank"
                  >Follow us on tik tok</a
                >
                <div class="icon">
                  <i class="fab fa-tiktok"></i>
                </div>
              </li>

              <!-- <li
              *ngFor="let item of SocialLinks| slice:1:2"
              class="social-media {{ item.type?.name }}"
            >
              <a href="{{ item.value }}" target="_blank"
                >Follow us on {{ item.type?.name }}</a
              >
              <div class="icon">
                <i class="fab fa-{{ item.type?.name }}"></i>
              </div>
              </li>

              <li
                *ngFor="let item of SocialLinks| slice:3:4"
                class="social-media {{ item.type?.name }}"
              >
                <a href="{{ item.value }}" target="_blank"
                  >Follow us on {{ item.type?.name }}</a
                >
                <div class="icon">
                  <i class="fab fa-{{ item.type?.name }}"></i>
                </div>
              </li>

              

              <li
                *ngFor="let item of SocialLinks| slice:0:1"
                class="social-media {{ item.type?.name }}"
              >
                <a href="{{ item.value }}" target="_blank"
                  >Follow us on {{ item.type?.name }}</a
                >
                <div class="icon">
                  <i class="fab fa-{{ item.type?.name }}"></i>
                </div>
              </li>

              

              <li class="social-media tiktok">
                <a
                  href="https://www.tiktok.com/@kellana5060?_t=8cMKHJrisci&_r=1"
                  target="_blank"
                  >Follow us on tik tok</a
                >
                <div class="icon">
                  <i class="fab fa-tiktok"></i>
                </div>
              </li>

              <li class="social-media snapchat">
                <a href="https://www.snapchat.com" target="_blank"
                  >Follow us on snapchat</a
                >
                <div class="icon">
                  <i class="fab fa-snapchat"></i>
                </div>
              </li> -->

            </ul>
          </div>
        </div>
        <div class="col-md-12 p-0" style="overflow-x: hidden">
          <router-outlet></router-outlet>
        </div>
        <div class="left-side">
          <button class="left-side-btn" >
            <span >

               <a href="https://donate.kellana.org.sa/" target="_blank"  style="color: white;">
                تبرع الآن     
              </a>

            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-etaam-footer></app-etaam-footer>
</div>
