import { Component, OnInit } from "@angular/core";
import { PartenersService } from "../../services/parteners.service";

@Component({
  selector: "app-ministries",
  templateUrl: "./ministries.component.html",
  styleUrls: ["./ministries.component.scss"],
})
export class MinistriesComponent implements OnInit {

  // ministries list empty object
  ministries_list: any[] = [];

  pageSize: number = 10;
  totalItems: number = 0;
  currentPage: number = 1;

  constructor(
    private service: PartenersService
  ) { }

  ngOnInit(): void {
    this.getMinistries();
  }

  // get ministries data from api
  getMinistries() {
    this.service.getMinistriesList().subscribe((res: any) => {
      // push ministries data to object
      this.ministries_list = res.content;
    });
  }
}
