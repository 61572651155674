<div class="full-width">
    <div class="page-title container">
        <div class="container">
            <h3>
                General pages            
            </h3>
        </div>
    </div>
</div>
<div class="container ">
    <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-12 no-padding">
            <img class="img-responsive" src="assets/images/about.jpg">
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 no-padding">
            <div class="text-padding">
                <h3 class="main-title">About Our Website</h3>
                <p class="main-pargraph">For a remarkable About page, all you need to do is figure out your company's unique identity, and then share it with the world. Easy, right? Of course not. Your "About Us" page is one of the most important pages on your website, and it needs to be well crafted. This profile also happens to be one of the most commonly overlooked pages, which is why you should make it stand out.</p>
            </div>
           
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-12 no-padding">
            <div class="text-padding">
                <h3 class="main-title">Our Mission</h3>
                <p class="main-pargraph">For a remarkable About page, all you need to do is figure out your company's unique identity, and then share it with the world. Easy, right? Of course not. Your "About Us" page is one of the most important pages on your website, and it needs to be well crafted. This profile also happens to be one of the most commonly overlooked pages, which is why you should make it stand out.</p>
            </div>

           
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 no-padding">
            <img class="img-responsive" src="assets/images/mission.jpg">

        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-12 no-padding">
            <img class="img-responsive" src="assets/images/vision.jpg">

        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 no-padding">

            <div class="text-padding">
                <h3 class="main-title">Our Vision</h3>
                <p class="main-pargraph">For a remarkable About page, all you need to do is figure out your company's unique identity, and then share it with the world. Easy, right? Of course not. Your "About Us" page is one of the most important pages on your website, and it needs to be well crafted. This profile also happens to be one of the most commonly overlooked pages, which is why you should make it stand out.</p>
            </div>
           
        </div>    
    </div>
</div>