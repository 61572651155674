<div class="full-width">
  <div class="row page-title">

    <div class="col-md-6 col-sm-6 col-xs-12">
      <h3>
        التطوع
      </h3>
    </div>

    <div class="col-md-6 col-sm-6 col-xs-12">
      <nav aria-label="breadcrumb" class="titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
          <li class="breadcrumb-item" style="padding-left: 0px;"><a routerLink="../volunteers"
              style="padding-right: 10px;">التطوع</a></li>
        </ol>
      </nav>
    </div>

  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-6 col-sm-12 col-xs-12">
      <div class="grey-card my-4">
        <img class="volunteer-img" src="{{ volunteer.image }}" alt="volunteer">
      </div>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 card-text">
      <h4>{{ volunteer.title }}</h4>

      <p>
        {{ volunteer.description }}
      </p>

      <button class="Volunteer-now btn" style="width: 100% !important;" routerLink="/volunteers/volunteer-now">تطوع الان</button>

    </div>
  </div>
</div>
