<div class="row">
    <div class="col-md-6 col-sm-6 col-xs-12">
        <a routerLink="../articles/732">
            <div  class="card">
                <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <img class="card-img-top" src="assets/images/news1.jpeg">
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12">
                        <h3 class="card-title">الجمعية تجهز سلة وقائية لتوزيعها على مرضى الفشل الكلوي</h3>
                        <!-- <p class="p-date" *ngIf="article.module_calendar == 1 ">{{article.start_date}}</p>
                        <p class="p-date" *ngIf="article.module_calendar == 2 ">{{article.start_date_hijri}}</p> -->
                        <p  class="">
                            عقدت إدارة تطوير الشراكة المجتمعية اجتماعا تنسيقيا مع ممثل جمعية الأمير فهد بن سلمان الخيرية لرعاية مرضى الفشل الكلوي الأستاذ محمد الحربي المشرف على البرامج التوعوية والتعليمية في الجمعية 
                        </p>
        
                        <button class="red-btn"> إقرأ المزيد</button>
                    </div>
        
                </div>
            </div>
        </a>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12">
        <a routerLink="../articles/731">
            <div  class="card">
                <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <img class="card-img-top" src="assets/images/news2.jpg">
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12">
                        <h3 class="card-title">الأمير عبدالعزيز بن سلمان يتفقد مرضى جمعية “كلانا” المنومين في مركز ديماس في الرياض</h3>
                        <!-- <p class="p-date" *ngIf="article.module_calendar == 1 ">{{article.start_date}}</p>
                        <p class="p-date" *ngIf="article.module_calendar == 2 ">{{article.start_date_hijri}}</p> -->
                        <p  class="">
                            تفقد صاحب السمو الملكي الأمير عبدالعزيز بن سلمان بن عبدالعزيز رئيس مجلس إدارة جمعية الأمير فهد بن سلمان الخيرية لرعاية مرضى الفشل الكلوي "كلانا" اليوم, مرضى الجمعية
                        </p>
        
                        <button class="red-btn"> إقرأ المزيد</button>
                    </div>
        
                </div>
            </div>
        </a>
    </div>
</div>