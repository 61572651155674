import { Component, OnInit } from '@angular/core';
import { CommonGeneralService } from '../../../general-pages/pages/services/common-general.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-cards4',
  templateUrl: './cards4.component.html',
  styleUrls: ['./cards4.component.scss']
})
export class Cards4Component implements OnInit {

  
  whoWeAre: any = {};
  vision: any = {};
  mission:any = {};
  goals:any = {};
  constructor(private commonPagesService: CommonGeneralService) { }

  ngOnInit(): void {
    forkJoin([this.commonPagesService.getWhoWeAre(), this.commonPagesService.getVision(), this.commonPagesService.getMission(), this.commonPagesService.getGoals()]).subscribe((res:any) => {
      this.whoWeAre = res[0].content[0];
      this.vision = res[1].content[0];
      this.mission = res[2].content[0];
      this.goals = res[3].content[0];
    })
  }

}
