import { BaseHttpService } from "src/app/services/base-http.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})

export class AwqafService {

  constructor(
    private http: BaseHttpService
  ) { }

  // get al awgaf list data
  getAwqaf() {
    return this.http.post("/api/cms", {
      module_id: 2879,
      "lang_id": 2,
      "application_id": 188,      
    });
  }
}
