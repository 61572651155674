import { Component, OnInit } from '@angular/core';
import { PartenersService } from '../../services/parteners.service';

@Component({
  selector: 'app-newspaper',
  templateUrl: './newspaper.component.html',
  styleUrls: ['./newspaper.component.scss']
})
export class NewspaperComponent implements OnInit {

  // newspaper list empty object
  newspaper_list: any[] = [];

  pageSize: number = 10;
  totalItems: number = 0;
  currentPage: number = 1;

  constructor(
    private service: PartenersService
  ) { }

  ngOnInit(): void {
    this.getNewspaper();
  }

  // get newspaper_list data from api
  getNewspaper() {
    this.service.getNewspaperList(this.currentPage).subscribe((res: any) => {

      // push newspaper_list data to object
      this.newspaper_list = res.content;
    })
  }

}
