import { Component, OnInit } from "@angular/core";
import {
  SwiperConfigInterface,
  SwiperPaginationInterface,
} from "ngx-swiper-wrapper";

import { AboutService } from "../../services/about.service";

@Component({
  selector: "app-campaign",
  templateUrl: "./campaign.component.html",
  styleUrls: ["./campaign.component.scss"],
})

export class CampaignComponent implements OnInit {

  slides: any[] = [];

  downloadFile;

  private pagination: SwiperPaginationInterface = {
    el: ".swiper-pagination",
    clickable: true,
  };

  campaigns;
  campaigns_tools;

  config: SwiperConfigInterface = {
    a11y: true,
    direction: "horizontal",
    slidesPerView: 1,

    navigation: true,
    pagination: this.pagination,
    // grabCursor: true,

    effect: "slide",
    speed: 1500,
  };

  constructor(private AboutService: AboutService) { }

  ngOnInit(): void {
    this.AboutService.getCampaign().subscribe((res: any) => {
      this.campaigns = res.content;
    });

    this.AboutService.getCampaignTools().subscribe((res: any) => {
      this.campaigns_tools = res.content;
    })

    this.slides = [
      { image: "../../../assets/images/health3.jpg" },
      { image: "../../../assets/images/health4.jpg" },
      { image: "../../../assets/images/health5.jpg" },
      { image: "../../../assets/images/health6.jpg" },
    ];
    this.getPdfUrl()
  }

  getPdfUrl() {
    this.AboutService.getPdfFile().subscribe((res: any) => {
      this.downloadFile = res.content[0].file;
    })
  }

  downloadPDFFile() {
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("href", this.downloadFile);
    link.setAttribute("download", `programs.png`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
