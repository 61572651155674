import { BaseHttpService } from "src/app/services/base-http.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CommonGeneralService {
  constructor(
    private baseHttpService: BaseHttpService
  ) { }

  // call api to get who we are
  getWhoWeAre() {
    return this.baseHttpService.post("/api/pages/show", {
      id: 8,
    },
      {}
    );
  }

  // call api to ge mission
  getMission() {
    return this.baseHttpService.post("/api/pages/show", {
      id: 11,
    },
      {}
    );
  }

  // call api to ge vision
  getVision() {
    return this.baseHttpService.post("/api/pages/show", {
      id: 10,
    },
      {}
    );
  }

  // call api to ge goals
  getGoals() {
    return this.baseHttpService.post("/api/pages/show", {
      id: 12,
    },
      {}
    );
  }

  // call api to ge contact us data
  getContactUsData() {
    return this.baseHttpService.post("/api/contact_details/show", {
      application_id: 188,
      id: 173,
      lang_id: 1,
      module_id: "2853",
    },
      {}
    );
  }

  // call api to submit contact us form
  submitContactUsForm(body) {
    return this.baseHttpService.post("/api/contact_us/store", body);
  }

  // call api to submit complaint form
  submitComplaintForm(body) {
    return this.baseHttpService.post("/api/cms/store", body);
  }

  // get contact message title
  getContactMessageTitle() {
    return this.baseHttpService.post("/api/contact_us_messages", {
      application_id: 25,
      lang_id: 1,
    },
      {}
    );
  }

  // get social links
  getFooterSocialLinks() {
    return this.baseHttpService.post("/api/social_media_values", {
      module_id: 420,
    },
      {}
    );
  }

  // get friendly websites list
  getFriendlyWebsiteslist() {
    return this.baseHttpService.post("/api/cms", {
      module_id: 430,
    },
      {}
    );
  }

  // get privacy policy
  getPrivacyPolicy() {
    return this.baseHttpService.post("/api/pages/show", {
      id: 17,
    },
      {}
    );
  }

  // get terms
  getTerms() {
    return this.baseHttpService.post("/api/pages/show", {
      id: 16,
    },
      {}
    );
  }

  // get visitors
  getVisitors() {
    return this.baseHttpService.get("/api/visits");
  }


  getinfoRightslearnlist() {
    return this.baseHttpService.post("/api/topic_categories", {
      application_id: 188,
      lang_id: 1,
    },
      {}
    );
  }

  getPatientRightslist() {
    return this.baseHttpService.post("/api/topics", {
      application_id: 188,
      lang_id: 1,
      topic_category_id:2,

    },
      {}
    );
  }

  getlearnmorelist() {
    return this.baseHttpService.post("/api/topics", {
      topic_category_id:1,
      application_id: 188,
      lang_id: 1 

    },
      {}
    );
  }


}
