import { Component, OnInit } from "@angular/core";
import {
  SwiperConfigInterface,
  SwiperPaginationInterface,
} from "ngx-swiper-wrapper";

import { AwqafService } from "../../services/awqaf.service";

@Component({
  selector: "app-awkaf",
  templateUrl: "./awkaf.component.html",
  styleUrls: ["./awkaf.component.scss"],
})
export class AwkafComponent implements OnInit {

  slides: any[] = [];

  private pagination: SwiperPaginationInterface = {
    el: ".swiper-pagination",
    clickable: true,
  };

  config: SwiperConfigInterface = {
    a11y: true,
    direction: "horizontal",
    slidesPerView: 1,
    navigation: true,
    pagination: this.pagination,
    // grabCursor: true,
    effect: "slide",
    speed: 1500,
  };

  awqafList;

  constructor(
    private AwqafService: AwqafService
  ) { }

  ngOnInit(): void {

    // this.slides = [
    //   { image: "../../../assets/images/awqaf1.jpg" },
    //   { image: "../../../assets/images/awqaf2.jpg" },
    //   { image: "../../../assets/images/awqaf3.jpg" },
    //   { image: "../../../assets/images/awqaf4.jpg" },
    //   { image: "../../../assets/images/awqaf5.jpg" },
    //   { image: "../../../assets/images/awqaf6.jpg" },
    //   { image: "../../../assets/images/awqaf7.jpg" },
    //   { image: "../../../assets/images/awqaf8.jpg" },
    // ];

    // call api and get list data
    this.AwqafService.getAwqaf().subscribe((res: any) => {
      this.awqafList = res.content;
    });
  }
}
