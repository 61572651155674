<div class="row">
        <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="card">
                <img class="card-img" src="assets/images/program1.png">
                <h6 class="card-title">برنامج زراعة الكلى للمرضى المحتاجين</h6>
                <p class="card-paragraph">برنامج زراعة الكلى يهدف لمساعدة المرضى وتحسين نوعية الحياة لهم ويخدم المرضى المواطنين والمقيمين</p>
                <a class="read-more" routerLink="1">
                    اقرأ المزيد
                </a>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="card">
                <img class="card-img" src="assets/images/program2.png">
                <h6 class="card-title">برنامج رعاية غسيل دموي لمرض الفشل الكلوي</h6>
                <p class="card-paragraph">تسعى الجمعية من خلال هذا البرنامج إلى تأمين جلسات الغسيل الكلوي الدموي ( بواقع ثلاث جلسات أسبوعياً ) لمرضى الفشل الكلوي</p>
                <a class="read-more" routerLink="2">
                    اقرأ المزيد
                </a>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="card">
                <img class="card-img" src="assets/images/program3.png">
                <h6 class="card-title">برنامج قبول الطلاب والطالبات في المؤسسات التعليمية</h6>
                <p class="card-paragraph">بادرت الجمعية إلى توقيع مذكرات تفاهم مع (26) مؤسسة تعليمية حكومية و (1) مؤسسة تعليمية أهلي</p>
                <a class="read-more" routerLink="3">
                    اقرأ المزيد
                </a>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="card">
                <img class="card-img" src="assets/images/program4.png">
                <h6 class="card-title">برنامج التوظيف</h6>
                <p class="card-paragraph">قامت جمعية "كِـلانا" بتوقيع اتفاقية مع وزارة الموارد البشرية والتنمية الاجتماعية وصندوق تنمية الموارد البشرية </p>
                <a class="read-more">
                    اقرأ المزيد
                </a>
            </div>
        </div>
</div>