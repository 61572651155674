<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 login_bar">
            <h2>{{ "AUTH.LOGIN.login" | translate }}</h2>
        </div>

        <div class="col-md-12 col-sm-12 col-xs-12 mt-5 mb-5 login_wrapper">
            <div class="empty_wrapper"></div>
            <div class="form_wrapper">
                <h2>{{ "AUTH.LOGIN.login" | translate }}</h2>
                <form [formGroup]="loginForm" (ngSubmit)="submitForm()">
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="email" [placeholder]="'AUTH.REGISTER.EMAIL' | translate" />
                        <div *ngIf="
                loginForm.get('email').touched && loginForm.get('email').invalid
              ">
                            <div *ngIf="loginForm.get('email').errors.required" class="text-right validator-error">
                                {{ "VALIDATORS.REQUIRED" | translate }}
                            </div>
                            <div *ngIf="
                  loginForm.get('email').invalid &&
                  !loginForm.get('email').errors.required
                " class="text-right validator-error">
                                {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <input type="password" class="form-control" formControlName="password" [placeholder]="'Password' | translate" />
                        <div *ngIf="
                loginForm.get('password').touched &&
                loginForm.get('password').invalid
              ">
                            <div *ngIf="loginForm.get('password').errors.required" class="text-right validator-error">
                                {{ "VALIDATORS.REQUIRED" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="forgetPassword">
                        <a [routerLink]="['/reset-password']">{{
              "AUTH.LOGIN.forgetPassword" | translate
            }}</a>
                    </div>
                    <button class="btn btn-xs btn-custom-sec new" [disabled]="!loginForm.valid" type="submit">
            {{ "Login" | translate }}
          </button>
                    <div class="register">
                        <p>{{ "AUTH.LOGIN.no_account" | translate }}</p>
                        <a [routerLink]="['/registration']">{{
              "AUTH.LOGIN.register" | translate
            }}</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>