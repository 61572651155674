<div class="container-fluid shadow header">
  <div class="row justify-content-center">
    <div class="col-md-10">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" routerLink="/">
          <img class="logo" src="assets/images/logo.PNG" alt="website-logo" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse pull-right text-right"
          id="navbarNavDropdown"
        >
          <ul class="navbar-nav mar-right">
            <!-- home -->
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="FixTheStyle()"
                routerLink="home"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                routerLinkActive="active-link"
                >الرئيسية
              </a>
            </li>
            <!-- about -->
            <li class="nav-item dropdown">
              <a
                class="nav-link"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                routerLinkActive="active-link"
              >
                عن الجمعية
              </a>
              <div
                class="dropdown-menu dropdown-list"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                aria-labelledby="notification"
              >
                <li>
                  <a class="nav-link dropdown-item" routerLink="about">
                    <span class="float-right"> من نحن </span>
                  </a>
                </li>
                <li>
                  <a
                    class="nav-link dropdown-item"
                    routerLink="/about/director"
                  >
                    <span class="float-right"> أعضاء مجلس الإدارة </span>
                  </a>
                </li>
                <li>
                  <a
                    class="nav-link dropdown-item"
                    routerLink="/about/stratigic"
                  >
                    <span class="float-right"> الخطة الاستراتيجية </span>
                  </a>
                </li>
                <li>
                  <a class="nav-link dropdown-item" routerLink="/about/coc">
                    <span class="float-right"> أسرة كلانا </span>
                  </a>
                </li>

                <!-- governance -->
                <li>
                  <div class="dropdown dropleft">
                    <a
                      class="dropdown-toggle dropdown-item nav-link"
                      id="dLabel"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span class="float-right"> الحوكمة </span>
                    </a>
                    <ul
                      class="dropdown-menu dropdown-list"
                      aria-labelledby="dLabel"
                    >
                      <li *ngFor="let item of governance_list |slice:4:10">
                        <a
                          class="nav-link dropdown-item"
                          routerLink="about/governance/{{ item.id }}"
                          target="blank"
                        >
                          <span class="float-right">{{ item.title }}</span>
                        </a>
                      </li>

                      <li *ngFor="let item of governance_list |slice:10:11">

                          <div class="dropdown dropleft">
                            <a
                            class="dropdown-toggle dropdown-item nav-link"
                            id="dLabel"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            >
                                <span class="float-right">{{ item.title }}</span>
                            </a>

                            <ul
                            class="dropdown-menu dropdown-list"
                            aria-labelledby="dLabel"
                            >
                               <li>
                                <a routerLink="/about/executive-members" 
                                  class="nav-link dropdown-item">
                                  <span class="float-right">  أعضاء اللجنة التنفيذية </span>
                                </a>
                              </li>

                              <li>
                                <a routerLink="/about/resource-development-members"  
                                  class="nav-link dropdown-item">
                                  <span class="float-right">    أعضاء لجنة تنمية الموارد </span>
                                </a>
                              </li>

                              <li>
                                <a routerLink="/about/health-members"  
                                  class="nav-link dropdown-item">
                                  <span class="float-right">  أعضاء لجنة الخدمات الصحية والاجتماعية   </span>
                                </a>
                              </li>

                              <li>
                                <a routerLink="/about/media-members"  
                                  class="nav-link dropdown-item">
                                  <span class="float-right">  أعضاء اللجنة الإعلامية والتوعوية الصحية   </span>
                                </a>
                              </li>

                            </ul>
                          </div>
                       
                      </li>

                      <li *ngFor="let item of governance_list |slice:11:20">
                        <a
                          class="nav-link dropdown-item"
                          routerLink="about/governance/{{ item.id }}"
                          target="blank"
                        >
                          <span class="float-right">{{ item.title }}</span>
                        </a>
                      </li>

                    </ul>
                  </div>
                </li>
                
                <li>
                  <a class="nav-link dropdown-item" routerLink="/about/members">
                    <span class="float-right"> العضوية </span>
                  </a>
                </li>
                <li>
                  <a
                    class="nav-link dropdown-item"
                    routerLink="/about/campaign"
                  >
                    <span class="float-right"> صحتنا في كلانا </span>
                  </a>
                </li>
              </div>
            </li>
            <!-- programs -->
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="FixTheStyle()"
                routerLink="programs"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                routerLinkActive="active-link"
              >
                البرامج
              </a>
            </li>
            <!-- news -->
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="FixTheStyle()"
                routerLink="articles"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                routerLinkActive="active-link"
                >الأخبار
              </a>
            </li>
            <!-- ministry -->
            <li class="nav-item dropdown">
              <a
                class="nav-link"
                href="#"
                id="notification"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                routerLinkActive="active-link"
              >
                شركاؤنا
              </a>

              <div
                class="dropdown-menu dropdown-list"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                aria-labelledby="notification"
              >
                <li>
                  <a
                    class="nav-link dropdown-item"
                    routerLink="/partener/ministry"
                  >
                    <span class="float-right"> الوزارات </span>
                  </a>
                </li>
                <li>
                  <a
                    class="nav-link dropdown-item"
                    routerLink="/partener/charity"
                  >
                    <span class="float-right">
                      الجمعيات و المؤسسات الخيرية
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    class="nav-link dropdown-item"
                    routerLink="/partener/university"
                  >
                    <span class="float-right">
                      الجامعات الحكومية و الأهلية
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    class="nav-link dropdown-item"
                    routerLink="/partener/communication-company"
                  >
                    <span class="float-right"> شركات الاتصالات </span>
                  </a>
                </li>
                <li>
                  <a class="nav-link dropdown-item" routerLink="/partener/bank">
                    <span class="float-right"> البنوك </span>
                  </a>
                </li>

                <li>
                  <a
                    class="nav-link dropdown-item"
                    routerLink="/partener/newspaper"
                  >
                    <span class="float-right"> الصحف </span>
                  </a>
                </li>
                <li>
                  <a
                    class="nav-link dropdown-item"
                    routerLink="/partener/other"
                  >
                    <span class="float-right"> جهات أخرى </span>
                  </a>
                </li>
              </div>
            </li>
            <!-- awqaf -->
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="FixTheStyle()"
                routerLink="awqaf"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                routerLinkActive="active-link"
                >الوقف
              </a>
            </li>
            <!-- donation -->
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://donate.kellana.org.sa/"
                target="_blank"
                >تبرع
              </a>
            </li>
            <!-- volunteer -->
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="FixTheStyle()"
                routerLink="volunteers"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                routerLinkActive="active-link"
                >التطوع
              </a>
            </li>
            <!-- recruitment -->
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="FixTheStyle()"
                routerLink="recruitment"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                routerLinkActive="active-link"
                >التوظيف
              </a>
            </li>
            <!-- contact us -->
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="FixTheStyle()"
                routerLink="contact"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                routerLinkActive="active-link"
                >اتصل بنا
              </a>
            </li>
            <!-- pages -->
            <!-- <li class="nav-item">
              <a
                class="nav-link pages"
                routerLink="pages"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                routerLinkActive="active-link"
                >الصفحات
              </a>
            </li> -->

            <!-- <li class="nav-item" *ngIf="isLogin">
                            <a class="nav-link pages" (click)="FixTheStyle()" routerLink="forms/form-add" data-toggle="collapse" data-target=".navbar-collapse.show" routerLinkActive="active-link">اضافة نموذج
                </a>
                        </li> -->

            <!-- <li class="nav-item">
                            <a class="nav-link pages" (click)="FixTheStyle()" routerLink="forms" data-toggle="collapse" data-target=".navbar-collapse.show" routerLinkActive="active-link">النماذج
                </a>
                        </li> -->

            <!-- login -->
            <li class="nav-item" *ngIf="!username">
              <a
                class="nav-link btn btn-xs btn-custom-sec new"
                routerLink="login"
                routerLinkActive="active-link"
                >{{ "Login" | translate }}
              </a>
            </li>
            <!-- user menu -->
            <li class="nav-item dropdown" *ngIf="username">
              <a
                class="nav-link"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                routerLinkActive="active-link"
              >
                <span>{{ username }}</span>
              </a>
              <div
                class="dropdown-menu dropdown-list"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                aria-labelledby="user_menu"
              >
                <li>
                  <a class="nav-link dropdown-item" routerLink="profile">
                    <mat-icon class="flot-right">person</mat-icon>
                    <span> الملف الشخصي </span>
                  </a>
                </li>
                <li>
                  <a class="nav-link dropdown-item" routerLink="staticPages">
                    <mat-icon class="flot-right">dashboard</mat-icon>
                    <span>الخدمات الالكترونية </span>
                  </a>
                </li>
                <li>
                  <a class="nav-link dropdown-item"
                    (click)="Logout()"
                    style="cursor: pointer"
                    routerLinkActive="active-link"
                  >
                    <mat-icon class="flot-right">logout</mat-icon>
                    <span>
                      {{ "HEADER.LOGOUT" | translate }}
                    </span>
                  </a>
                </li>
              </div>
            </li>
            <!-- notifications -->
            <li
              class="nav-item dropdown pull-right desktop-only"
              *ngIf="username"
            >
              <a
                class="nav-link notification-button"
                href="#"
                id="notification"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <mat-icon [matBadge]="notification_count" matBadgeColor="warn"
                  >notifications</mat-icon
                >
              </a>
              <div
                class="dropdown-menu notifications-list"
                aria-labelledby="notification"
              >
                <li *ngFor="let item of notificationsList">
                  <a
                    class="nav-link dropdown-item  {{
                      item.read_at ? 'notification-read' : ''
                    }}"
                    id="notify`{{ item.id }}`"
                    (click)="readselected(item)"
                  >
                    <span class="float-right">
                      {{ item.notification_text }}
                    </span>
                    <br />
                    <small class="float-left time">
                      {{ item.since }}
                    </small>
                  </a>
                </li>
                <li *ngIf="!isLength">
                  <button
                    class="button-48 notification-button"
                    (click)="seeMore()"
                    role="button"
                  >
                    <span class="text" style="color: #ab2023"> المزيد</span>
                  </button>
                </li>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>
