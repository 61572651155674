<div class="row">
    <div class="col-md-4 col-sm-12 col-xs-12">

        <div class="home-article">
            <a  class="cover-img" style="background-image: url(assets/images/king.jpg);">
                <div class="home-article-desc text-left">
                    <h4 class="card-title"> وزير الشؤون الإسلامية : أمر خادم الحرمين الشريفين يؤكد قربه الدائم من أبنائه المواطنين في جميع الظروف</h4>
                    <small class="p-date">1441-08-11</small>
                    <p class="card-paragraph">
                        الرياض 10 شعبان 1441 هـ الموافق 03 إبريل 2020 م واس
    
                        نوه معالي وزير الشؤون الإسلامية والدعوة والإرشاد الشيخ الدكتور عبداللطيف بن عبدالعزيز آل الشيخ بأمر خادم الحرمين الشريفين الملك سلمان بن عبدالعزيز آل سعود - أيده الله - بأن تتحمل الحكومة من خلال نظام (ساند) 60 % من رواتب موظفي القطاع الخاص السعوديين في المنشآت المتأثرة من التداعيات الحالية جراء انتشار فيروس كورونا المستجد ( كوفيد - 19 )، رافعاً معاليه شكره لخادم الحرمين الشريفين - رعاه الله - على الأمر، ومشيراً أنه يؤكد حرصه وعنايته بأبنائه المواطنين، ومدى قربه - حفظه الله - منهم في مختلف الظروف.
                        
                        وأكد معاليه أن هذا الأمر يمثل مبادرة ليست جديدة ولا مستغربة من خادم الحرمين الشريفين - حفظه الله -، إذ تأتي امتداداً لعطاءته ومكرماته المتوالية، واصفاً الأمر باللفتة الانسانية التي تبين وبكل وضوح استشعار خادم الحرمين الشريفين - حفظه الله - للآثار المترتبة على الأزمة الاقتصادية التي يمر بها العالم أجمع جراء هذه الجائحة العالمية.
                        
                        وفي ختام تصريحه سأل معاليه الله تعالى أن يجزل الأجر والمثوبة لخادم الحرمين الشريفين وسمو ولي عهده الأمين ، وأن يبارك في جهودهما وأعمالهما ويزيدهما توفيقاً وتسديداً لكل مافيه خير البلاد والعباد ، وأن يحفظ المملكة وشعبها الكريم من الأوبئة ومن كل سوء ومكروه.
                    </p>
    
    
                </div>
            </a>
        </div>

    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
        
        <div class="home-article">
            <a  class="cover-img" style="background-image: url(assets/images/ceo.jpg);">
                <div class="home-article-desc text-left">
                    <h4 class="card-title"> وزير الشؤون الإسلامية : أمر خادم الحرمين الشريفين يؤكد قربه الدائم من أبنائه المواطنين في جميع الظروف</h4>
                    <small class="p-date">1441-08-11</small>
                    <p class="card-paragraph">
                        الرياض 10 شعبان 1441 هـ الموافق 03 إبريل 2020 م واس
    
                        نوه معالي وزير الشؤون الإسلامية والدعوة والإرشاد الشيخ الدكتور عبداللطيف بن عبدالعزيز آل الشيخ بأمر خادم الحرمين الشريفين الملك سلمان بن عبدالعزيز آل سعود - أيده الله - بأن تتحمل الحكومة من خلال نظام (ساند) 60 % من رواتب موظفي القطاع الخاص السعوديين في المنشآت المتأثرة من التداعيات الحالية جراء انتشار فيروس كورونا المستجد ( كوفيد - 19 )، رافعاً معاليه شكره لخادم الحرمين الشريفين - رعاه الله - على الأمر، ومشيراً أنه يؤكد حرصه وعنايته بأبنائه المواطنين، ومدى قربه - حفظه الله - منهم في مختلف الظروف.
                        
                        وأكد معاليه أن هذا الأمر يمثل مبادرة ليست جديدة ولا مستغربة من خادم الحرمين الشريفين - حفظه الله -، إذ تأتي امتداداً لعطاءته ومكرماته المتوالية، واصفاً الأمر باللفتة الانسانية التي تبين وبكل وضوح استشعار خادم الحرمين الشريفين - حفظه الله - للآثار المترتبة على الأزمة الاقتصادية التي يمر بها العالم أجمع جراء هذه الجائحة العالمية.
                        
                        وفي ختام تصريحه سأل معاليه الله تعالى أن يجزل الأجر والمثوبة لخادم الحرمين الشريفين وسمو ولي عهده الأمين ، وأن يبارك في جهودهما وأعمالهما ويزيدهما توفيقاً وتسديداً لكل مافيه خير البلاد والعباد ، وأن يحفظ المملكة وشعبها الكريم من الأوبئة ومن كل سوء ومكروه.
                    </p>
    
    
                </div>
            </a>
        </div>

    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
        
        <div class="home-article">
            <a  class="cover-img" style="background-image: url(assets/images/king.jpg);">
                <div class="home-article-desc text-left">
                    <h4 class="card-title"> وزير الشؤون الإسلامية : أمر خادم الحرمين الشريفين يؤكد قربه الدائم من أبنائه المواطنين في جميع الظروف</h4>
                    <small class="p-date">1441-08-11</small>
                    <p class="card-paragraph">
                        الرياض 10 شعبان 1441 هـ الموافق 03 إبريل 2020 م واس
    
                        نوه معالي وزير الشؤون الإسلامية والدعوة والإرشاد الشيخ الدكتور عبداللطيف بن عبدالعزيز آل الشيخ بأمر خادم الحرمين الشريفين الملك سلمان بن عبدالعزيز آل سعود - أيده الله - بأن تتحمل الحكومة من خلال نظام (ساند) 60 % من رواتب موظفي القطاع الخاص السعوديين في المنشآت المتأثرة من التداعيات الحالية جراء انتشار فيروس كورونا المستجد ( كوفيد - 19 )، رافعاً معاليه شكره لخادم الحرمين الشريفين - رعاه الله - على الأمر، ومشيراً أنه يؤكد حرصه وعنايته بأبنائه المواطنين، ومدى قربه - حفظه الله - منهم في مختلف الظروف.
                        
                        وأكد معاليه أن هذا الأمر يمثل مبادرة ليست جديدة ولا مستغربة من خادم الحرمين الشريفين - حفظه الله -، إذ تأتي امتداداً لعطاءته ومكرماته المتوالية، واصفاً الأمر باللفتة الانسانية التي تبين وبكل وضوح استشعار خادم الحرمين الشريفين - حفظه الله - للآثار المترتبة على الأزمة الاقتصادية التي يمر بها العالم أجمع جراء هذه الجائحة العالمية.
                        
                        وفي ختام تصريحه سأل معاليه الله تعالى أن يجزل الأجر والمثوبة لخادم الحرمين الشريفين وسمو ولي عهده الأمين ، وأن يبارك في جهودهما وأعمالهما ويزيدهما توفيقاً وتسديداً لكل مافيه خير البلاد والعباد ، وأن يحفظ المملكة وشعبها الكريم من الأوبئة ومن كل سوء ومكروه.
                    </p>
    
    
                </div>
            </a>
        </div>

    </div>
</div>