import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cards3',
  templateUrl: './cards3.component.html',
  styleUrls: ['./cards3.component.scss']
})
export class Cards3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
