import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss']
})
export class LoginPopupComponent implements OnInit {
  @Output() registrationRef = new EventEmitter();

  loginForm: FormGroup;
  token;

  adminloginform: FormGroup;
  applications: any;

  App_ID

  constructor(private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,) {

    this.loginForm = formBuilder.group({
      'email': ['', Validators.compose([Validators.required])],
      'password': ['', Validators.compose([Validators.required])],
      // 'application_id':['',Validators.compose([Validators.required])],
    })
    this.adminloginform = formBuilder.group({
      'username': ['', Validators.compose([Validators.required])],
      'password': ['', Validators.compose([Validators.required])]
    })


  }

  ngOnInit() {
    this.token = localStorage.getItem('login_token');

    document.getElementById("loginForm").style.display = "block";
    document.getElementById("adminloginform").style.display = "none";

    console.log("param from link:-", this.activatedRoute.snapshot.queryParams.url);

    this.authService.getApps().subscribe((res: any) => {
      this.applications = res.content;
      //  console.log ("Applications List:----",this.applications)

    })


  }

  register() {
    this.registrationRef.emit(true);
  }

  submitForm(formValue) {
    console.log("Form Values :-", this.loginForm.value)
    // localStorage.setItem('Application_ID',this.loginForm.get('application_id').value)
    // console.log("App ID from localstorage", localStorage.getItem('Application_ID'))
    this.authService.loginForm(this.loginForm.value).subscribe((res: any) => {

      if (res.status.code === 200) {

        this.toastr.success('تم بنجاح');
        localStorage.setItem('login_token', res.content.api_token)
        localStorage.setItem('login_assoc', res.content.association.name);
        localStorage.setItem('association_id', res.content.association.id);
        localStorage.setItem('user-info', res.content)

        if (this.activatedRoute.snapshot.queryParams.url) {
          this.router.navigateByUrl(`${this.activatedRoute.snapshot.queryParams.url}`).then(() =>
            window.location.reload()
          )

        }

        else {
          window.location.reload();
        }


      }

      else if (res.status.code === 401) {
        this.toastr.error(res.status.error_details);
      }

      else if (res.status.code === 403) {
        let validation_error = res.status.validation_errors;
        console.log(res.status.validation_errors)
        validation_error.forEach(element => {
          if (element.field == 'email') {
            this.toastr.error(element.message);
          } else if (element.field == 'password') {
            this.toastr.error(element.message);
          }
        })
      }

    }, error => {
      if (error.error.status.code === 403) {
        let validation_error = error.error.status.validation_errors;
        console.log(error.error.status.validation_errors)
        validation_error.forEach(element => {
          if (element.field == 'email') {
            this.toastr.error(element.message);
          } else if (element.field == 'password') {
            this.toastr.error(element.message);
          }
        })
      }
      else if (error.error.status.code === 401) {
        this.toastr.error(error.error.status.error_details);
      }

    })

  }

  submitAdminForm(formValue) {
    this.authService.loginAdminForm(this.adminloginform.value).subscribe((res: any) => {
      // console.log ("login response:",res)
      if (res.status.code === 200) {
        this.toastr.success('تم بنجاح');
        localStorage.setItem('user-info', JSON.stringify(res.content));
        localStorage.setItem('lang_id', JSON.stringify(res.content.lang_id))
        window.location.replace('https://appox.xyz/system/charity_box_v10/');

      }
      else if (res.status.code === 401) {
        this.toastr.error(res.status.error_details);
      }
      else if (res.status.code === 403) {
        let validation_error = res.status.validation_errors;
        console.log(res.status.validation_errors)
        validation_error.forEach(element => {
          if (element.field == 'username') {
            this.toastr.error(element.message);
          } else if (element.field == 'password') {
            this.toastr.error(element.message);
          }
        })
      }


    }, error => {
      if (error.error.status.code === 403) {
        let validation_error = error.error.status.validation_errors;
        console.log(error.error.status.validation_errors)
        validation_error.forEach(element => {
          if (element.field == 'username') {
            this.toastr.error(element.message);
          } else if (element.field == 'password') {
            this.toastr.error(element.message);
          }
        })

      }
      else if (error.error.status.code === 401) {
        this.toastr.error(error.error.status.error_details);
      }
    })
  }


  showadminform() {
    document.getElementById("loginForm").style.display = "none";
    document.getElementById("adminloginform").style.display = "block";

  }

  showaGuestform() {
    document.getElementById("loginForm").style.display = "block";
    document.getElementById("adminloginform").style.display = "none";

  }

}
