<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 " style="text-align: center;">
        <h4 class="main-title">خدمات صحية واجتماعية برؤية إنسانية خيرية واعية</h4>
    </div>
  <div *ngFor="let item of statistics" class="col-md-3 col-sm-3 col-xs-12 right-border">
    <div class="row">
      <div class="col-md-3 col-sm-3 col-xs-3 ">
        <h2 class="statistics-number">{{item.value}}</h2>
      </div>
      <div class="col-md-9 col-sm-9 col-xs-9 ">
        <h2 class="statistics-dots">--------------------</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-3 col-xs-3" style="text-align: center;">
        <img class="statistics-icon" [src]="item.image">
      </div>
      <div class="col-md-9 col-sm-9 col-xs-9 ">
        <p class="statistics-paragraph">{{item.title}}</p>
      </div>
    </div>
  </div>
  <!-- <div class="col-md-3 col-sm-3 col-xs-12 right-border">
        <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 ">
                <h2 class="statistics-number">156</h2>
            </div>
            <div class="col-md-9 col-sm-9 col-xs-9 ">
                <h2 class="statistics-dots">--------------------</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3" style="text-align: center;">
                <img class="statistics-icon" src="assets/images/kidneys.svg">
            </div>
            <div class="col-md-9 col-sm-9 col-xs-9 ">
                <p class="statistics-paragraph">جلسة غسيل دموي يحتاجها مريض الفشل الكلوي سنويا</p>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-sm-3 col-xs-12 right-border">
        <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 ">
                <h2 class="statistics-number">446</h2>
            </div>
            <div class="col-md-9 col-sm-9 col-xs-9 ">
                <h2 class="statistics-dots">--------------------</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3" style="text-align: center;">
                <img class="statistics-icon" src="assets/images/hospital (2).svg">
            </div>
            <div class="col-md-9 col-sm-9 col-xs-9 ">
                <p class="statistics-paragraph">مريض فشل كلوي يغسل في الجمعية</p>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-sm-3 col-xs-12 right-border">
        <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 ">
                <h2 class="statistics-number">28</h2>
            </div>
            <div class="col-md-9 col-sm-9 col-xs-9 ">
                <h2 class="statistics-dots">--------------------</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3" style="text-align: center;">
                <img class="statistics-icon" src="assets/images/Group 3341.svg">
            </div>
            <div class="col-md-9 col-sm-9 col-xs-9">
                <p class="statistics-paragraph">مركز غسيل تتعامل معهم الجمعية علي مستوي المملكة</p>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-sm-3 col-xs-12 ">
        <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3">
                <h2 class="statistics-number">28</h2>
            </div>
            <div class="col-md-9 col-sm-9 col-xs-9">
                <h2 class="statistics-dots">--------------------</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3" style="text-align: center;">
                <img class="statistics-icon" src="assets/images/riyal (1).svg">
            </div>
            <div class="col-md-9 col-sm-9 col-xs-9">
                <p class="statistics-paragraph">ريال التكلفة السنوية لمريض الفشل الكلوي بالجمعية</p>
            </div>
        </div>
    </div> -->
</div>
