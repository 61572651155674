import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circles3',
  templateUrl: './circles3.component.html',
  styleUrls: ['./circles3.component.scss']
})
export class Circles3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
