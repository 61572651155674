import * as $ from 'jquery';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CommonGeneralService } from 'src/app/general-pages/pages/services/common-general.service';
import { MenuService } from '../../../services/General/menu.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SubscribeService } from '../../shared_services/subscribe.service';
import { ToastrService } from 'ngx-toastr';

const VISITOR_COUNTER = 9;
@Component({
  selector: 'app-etaam-footer',
  templateUrl: './etaam-footer.component.html',
  styleUrls: ['./etaam-footer.component.scss'],
})
export class EtaamFooterComponent implements OnInit {

  myprofile: boolean = false;

  contactData: any = {};

  SubscribeForm: FormGroup;

  SocialLinks: any = {};

  visitors: any;

  facebooklink;

  instagramlink;

  youtubelink;

  twitterlink;

  snapchatlink: any;

  MenuItems;

  APP_ID;

  governance_list: any = {};

  constructor(
    private router: Router,
    private commonPagesService: CommonGeneralService,
    private subscribeseervicez: SubscribeService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private servvise: MenuService
  ) {
    this.SubscribeForm = formBuilder.group({
      email: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.commonPagesService.getContactUsData().subscribe((res: any) => {
      this.contactData = res.content[0];
    });

    this.servvise.getGovernance().subscribe((res: any) => {
      if (res.content) {
        this.governance_list = res.content;
      }
    })

    this.commonPagesService.getVisitors().subscribe((res: any) => {
      this.visitors = res.content.visits;
      setTimeout(() => {
        this.formatDigits();
      }, 1000);
    });

    this.commonPagesService.getFooterSocialLinks().subscribe((res: any) => {
      this.SocialLinks = res.content;
    });

    this.APP_ID = localStorage.getItem('Application_ID');
    if (this.APP_ID != null) {
      this.servvise.GetMenuItems().subscribe((res: any) => {
        this.MenuItems = res.Items;
        console.log('Menu Items =========>', this.MenuItems);
      });
    }
  }

  viewProfile() {
    if (this.myprofile == false) {
      this.myprofile = true;
    }

    window.scrollTo(0, 0);
  }

  closeProfile(val) {
    this.myprofile = val;
  }

  PostSubscribeEmail() {
    this.subscribeseervicez
      .PostEmail(this.SubscribeForm.value)
      .subscribe((res: any) => {
        // console.log("subscription res =====>",res)
        if (res.status.code === 200) {
          this.toastr.success('تم بنجاح');
          // console.log(res.status.message)
        } else if (res.status.code === 401) {
          this.toastr.error(res.status.error_details);
          // console.log(res.status.error_details)
        } else if (res.status.code === 400) {
          this.toastr.error(res.status.error_details);
          // console.log(res.status.error_details)
        } else if (res.status.code === 403) {
          this.toastr.error(res.status.validation_errors[0].message);
          // console.log(res.status.validation_errors[0].message)
        } else {
          this.toastr.error(res.status.error_details);
          // console.log(res.status.error_details)
        }
      });
  }

  formatDigits() {
    var text = $('#visitors').html();
    $('#visitors').html('');
    for (let i = 0; i <= text?.length - 1; i++) {
      var html = text.substr(i, 1);
      var sp = '<span class=\'digit\'>' + html;
      sp += ' </span> &nbsp;';
      $('#visitors').prepend(sp);
    }
    for (let j = 0; j <= VISITOR_COUNTER - text?.length; j++) {
      var sp = '<span class=\'digit\'>' + 0;
      sp += ' </span> &nbsp;';
      $('#visitors').append(sp);
    }
    /* var list = $('#visitors');
    var listItems = list.children('span');
    list.append(listItems.get().reverse()); */
  }

  NavigatetoModule(item) {
    $('body').removeClass(' nb-theme-default');
    localStorage.setItem('apiparameter', JSON.stringify(item.flow[0]));
    localStorage.setItem('ModuleID', JSON.stringify(item.name));
    localStorage.setItem('ModuleNo', JSON.stringify(item.module_id));
    console.log(
      'local storage set',
      JSON.parse(localStorage.getItem('apiparameter'))
    );

    // this.router.navigate(['/cmsforms'], { queryParams: { formparams:JSON.parse( item.flow[0]) } });

    if (item.flow[0].layout_id == 1) {
      this.router.navigate(['/cms/1'], {
        queryParams: { module: JSON.parse(localStorage.getItem('ModuleID')) },
      });
    } else if (item.flow[0].layout_id == 2) {
      this.router.navigate(['/cms/2'], {
        queryParams: { module: JSON.parse(localStorage.getItem('ModuleID')) },
      });
    } else if (item.flow[0].layout_id == 3) {
      // Go to General Pages Module
      this.router.navigate(['/cmspages'], {
        queryParams: { module: JSON.parse(localStorage.getItem('ModuleID')) },
      });
    }

    // else if (item.flow[0].name == "الاخبار" && item.flow[0].layout_id == 2){
    //   // Go to Forms  Module
    //   this.router.navigate(['/cmsforms'], { queryParams: { formparams: JSON.parse(item.flow[0].flow[1]) } });

    // }
  }

}
