<div class="full-width">
  <div class="login_bar">
    <h2>{{ "AUTH.NEW_PASSWORD.changePassword" | translate }}</h2>
  </div>
</div>
<div class="register internal-page">
  <div class="container pt-5">
    <a routerLink="/profile">
      <h3 class="mb-3 btn"> رجوع</h3>
    </a>

    <form [formGroup]="registrationForm" (ngSubmit)="submit(registrationForm)">
      <fieldset _ngcontent-dyb-c300="" class="scheduler-border">

        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 text-left">
            <div class="form-group">
              <label class="font--bold">{{'AUTH.NEW_PASSWORD.old_password'|translate}}</label>
              <input [type]="oldPassword" class="form-control"
                placeholder="{{'AUTH.NEW_PASSWORD.old_password'|translate}}" formControlName="old_password">
              <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"
                (click)="changoldPasswordVisiabilty()"></span>

              <span
                *ngIf="registrationForm.get('old_password').touched && registrationForm.get('old_password').invalid">
                <small *ngIf="registrationForm.get('old_password').errors.required" class="form-text required">
                  {{'VALIDATORS.REQUIRED'|translate}}
                </small>
              </span>
            </div>

          </div>

        </div>


        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 text-left">
            <div class="form-group">
              <label class="font--bold">{{'AUTH.NEW_PASSWORD.new_password'|translate}}</label>
              <input [type]="password" class="form-control" placeholder="{{'AUTH.NEW_PASSWORD.new_password'|translate}}"
                formControlName="password">
              <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"
                (click)="changPasswordVisiabilty()"></span>

              <span *ngIf="registrationForm.get('password').touched && registrationForm.get('password').invalid">
                <small *ngIf="registrationForm.get('password').errors.required" class="form-text required">
                  {{'VALIDATORS.REQUIRED'|translate}}
                </small>
                <small *ngIf="registrationForm.get('password').errors.pattern" class="form-text required">
                  {{' برجاء ادخال رمز سري مكون من 8 أحرف على الاقل و يشمل احرف كبيرة وصغيرة وارقام ورموز '}}
                </small>
              </span>
            </div>
          </div>


          <div class="col-md-12 col-sm-12 col-xs-12 text-left">
            <div class="form-group">
              <label class="font--bold">{{'AUTH.NEW_PASSWORD.confirm_password'|translate}}</label>
              <input [type]="confirmPassword" class="form-control"
                placeholder="{{'AUTH.NEW_PASSWORD.confirm_password'|translate}}" formControlName="confirm_password">
              <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"
                (click)="changConfirmPasswordVisiabilty()"></span>

              <span
                *ngIf="registrationForm.get('confirm_password').touched && registrationForm.get('confirm_password').invalid">
                <small *ngIf="registrationForm.get('confirm_password').errors.required" class="form-text required">
                  {{'VALIDATORS.REQUIRED'|translate}}
                </small>
                <small *ngIf="registrationForm.get('confirm_password').errors.pattern" class="form-text required">
                  {{' برجاء ادخال رمز سري مكون من 8 أحرف على الاقل و يشمل احرف كبيرة وصغيرة وارقام ورموز '}}
                </small>
                <small *ngIf="registrationForm.get('confirm_password').errors.notMatch" class="form-text required">
                  يجب ان يتطابق مع الرمز السري
                </small>
              </span>
            </div>
          </div>


        </div>


        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 text-left">
            <button class="btn-custom" type="submit">{{'AUTH.REGISTER.SEND'|translate}}</button>
          </div>
        </div>


      </fieldset>
    </form>
  </div>

</div>