import { Injectable } from "@angular/core";
import { BaseHttpService } from "src/app/services/base-http.service";
import { HttpHeaders, HttpRequest, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  header: HttpHeaders = new HttpHeaders();

  constructor(private baseHttpService: BaseHttpService) {}

  // get all notifications
  getAllNotifications(currentPage: Number, pageSize: Number) {
    const headers = this.header.set(
      "access-token",
      localStorage.getItem("login_token")
    );
    return this.baseHttpService.post(
      "/api/notifications",
      {
        application_id: 188,
        lang_id: 2,
      },
      {
        params: {
          page: currentPage,
          pagination: pageSize,
        },
        headers,
      }
    );
  }

  // update notification as read
  updateReadNotification(notificationId) {
    const headers = this.header.set(
      "access-token",
      localStorage.getItem("login_token")
    );
    return this.baseHttpService.post(
      "/api/notifications/update",
      {
        id: notificationId,
        lang_id: 2,
      },
      { headers }
    );
  }
}
